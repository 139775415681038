import { atom } from 'recoil';

export interface AppState {
  loading: boolean;
  tooltips: {
    cart: boolean;
    letsGetStarted: boolean;
    navigation: boolean;
  };
}

export const appDefault: AppState = {
  loading: false,
  tooltips: {
    cart: false,
    letsGetStarted: false,
    navigation: false
  }
};

/**
 * @name appAtom
 * @description tbd...
 */
export const appAtom = atom<AppState>({
  default: appDefault,
  key: 'app'
});
