import { IS_BROWSER, IS_PRODUCTION_ENV } from '@src/config/settings';
import { StoreIndexProductFieldsFragment } from '@src/generated/hooks';
import { logger } from '@src/utils/logger';

/**
 * @description These events should be generic so that we can implement our
 * other more specific event requirements independently (facebook vs. google)
 *
 * @external https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
 * @external https://developers.facebook.com/docs/meta-pixel/reference
 * @external https://developers.facebook.com/docs/meta-pixel/advanced/
 * @external https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
 */

const LOG_EVENTS = !IS_PRODUCTION_ENV && process.env.NODE_ENV !== 'test';
const facebook = IS_BROWSER && (window as any).fbq;

export interface TrackingProduct {
  brand: string;
  category?: string;
  id: string;
  indexProduct?: number;
  price: number;
  quantity?: number;
  title: string;
  variant: string;
}

export interface TrackingCart {
  items: TrackingProduct[];
}

/**
 * @name trackingDefaults
 * @description Some defaults that we can set for our GA property, I also
 * see a use-case where we might want to inject some ore specific data which
 * we can easily add an argument for.
 */
export const trackingDefaults = () => {
  const defaults = { country: 'US', currency: 'USD' };

  if (window.gtag) window.gtag('set', defaults);

  // if (LOG_EVENTS) logger.info({ defaults }, `🎯 Tracking defaults 🎯`);
};

/**
 * @name trackingAddToCart
 * @description Add to Cart | This event is triggered from the users store
 * and the bag route.
 */
export const trackingAddToCart = (data: TrackingProduct) => {
  const quantity = data.quantity ?? 1;

  const ga = {
    currency: 'USD',
    items: [
      {
        item_brand: data.brand,
        // item_category: "Google",
        item_id: data.id,
        item_name: data.title,
        item_variant: data.variant,
        price: data.price,
        quantity
      }
    ],
    value: data.price * quantity
  };

  const fb = {
    // content_category: data.category,
    content_ids: Array(quantity).fill(data.id),
    content_name: data.title,
    content_type: 'product',
    contents: [
      {
        id: data.id,
        quantity: 1
      }
    ],
    currency: 'USD',
    value: data.price
  };

  if (window.gtag) window.gtag('event', 'add_to_cart', ga);
  if (facebook) facebook('track', 'AddToCart', fb);

  if (LOG_EVENTS) logger.info({ fb, ga }, `🎯 Add to Cart 🎯`);
};

/**
 * @name trackingRemoveFromCart
 * @description Add to Cart | This event is triggered from the users store
 * and the bag route.
 */
export const trackingRemoveFromCart = (data: TrackingProduct) => {
  const quantity = data.quantity ?? 1;
  const ga = {
    currency: 'USD',
    items: [
      {
        item_brand: data.brand,
        // item_category: "Google",
        item_id: data.id,
        item_name: data.title,
        item_variant: data.variant,
        price: data.price,
        quantity
      }
    ],
    value: data.price * quantity
  };

  if (window.gtag) window.gtag('event', 'remove_from_cart', ga);
  // No facebook event for removing an item

  if (LOG_EVENTS) logger.info({ ga }, `🎯 Remove from Cart 🎯`);
};

/**
 * @name trackingCheckoutIntent
 * @description Checkout Intent | We fire this event when the user attempts
 * to checkout, when the data is valid they are redirected to Shopify for
 * payment details and redirected to a confirmation at the end of it all.
 */
export const trackingCheckoutIntent = (data: TrackingProduct[]) => {
  const count = data.reduce((acc, item) => {
    const q = item.quantity ?? 1;
    return acc + q;
  }, 0);

  const total = data.reduce((acc, item) => {
    const q = item.quantity ?? 1;
    return acc + item.price * q;
  }, 0);

  const ga = {
    currency: 'USD',
    items: data.map((item) => ({
      item_brand: item.brand,
      // item_category: "Google",
      item_id: item.id,
      item_name: item.title,
      item_variant: item.variant,
      price: item.price,
      quantity: item.quantity ?? 1
    })),
    value: total
  };

  const fb = {
    // content_category: product.category,
    content_ids: data.map((item) => Array(item.quantity).fill(item.id)).flat(),
    contents: data.map((product) => ({
      id: product.id,
      quantity: product.quantity ?? 1,
      variant: product.variant
    })),
    currency: 'USD',
    num_items: count,
    value: total
  };

  if (window.gtag) window.gtag('event', 'begin_checkout', ga);
  if (facebook) facebook('track', 'InitiateCheckout', fb);

  if (LOG_EVENTS) logger.info({ fb, ga }, `🎯 Start Checkout 🎯`);
};

/**
 * @name trackingClickProduct
 * @description This is the only event that feels a bit forced. We trigger it
 * when a user selects a product from the PDLP view, or when they click the
 * small underlined title in the list view.
 */
export const trackingClickProduct = (data: StoreIndexProductFieldsFragment) => {
  const ga = {
    currency: 'USD',
    items: [
      {
        item_brand: data.brand,
        // item_category: "Google",
        item_id: data.id,
        item_name: data.title
      }
    ],
    value: 0
  };

  if (window.gtag) window.gtag('event', 'select_item', ga);
  // No facebook event for this

  if (LOG_EVENTS) logger.info({ ga }, `🎯 Click Product 🎯`);
};

/**
 * @name trackingViewProducts
 * @description We trigger both an "item view" and "list view" events here.
 * Which event is determined by how many products are in the list, not taking
 * into account the "variants" of a product
 */
export const trackingViewProducts = (
  data: TrackingProduct[],
  isSingle = false
) => {
  const eventName = isSingle ? 'view_item' : 'view_item_list';
  const ga = {
    currency: 'USD',
    items: data.map((product) => ({
      index: product.indexProduct,
      item_brand: product.brand,
      // item_category: "Google",
      item_id: product.id,
      item_name: product.title,
      item_variant: product.variant,
      price: product.price,
      quantity: 1
    })),
    value: 0
  };

  const fb = {
    // content_category: '',
    content_ids: data.map((product) => product.id),
    // content_name: 'tbd...',
    content_type: 'product',
    contents: data.map((product) => ({
      id: product.id,
      quantity: 1,
      variant: product.variant
    })),
    currency: 'USD',
    value: 0
  };

  if (window.gtag) window.gtag('event', eventName, ga);
  if (facebook) facebook('track', 'ViewContent', fb);

  const logName = eventName === 'view_item' ? 'Product' : 'Products';
  if (LOG_EVENTS) logger.info({ eventName, fb, ga }, `🎯 View ${logName} 🎯`);
};

export const trackingGetStoreProductInfo = (
  products: StoreIndexProductFieldsFragment[],
  useDefaultVariant = false
) => {
  const data: TrackingProduct[] = [];

  products.forEach((product, indexProduct) => {
    product.variants.map((variant, index) => {
      // TODO: Make sure we grab the correct variant (follow up)
      if (useDefaultVariant && index !== 0) return;

      data.push({
        brand: product.brand,
        id: product.id,
        indexProduct,
        price: variant.price,
        quantity: 1,
        title: product.title,
        variant: variant.id
      });
    });
  });

  return data;
};
