import * as React from 'react';
import { useRouter } from 'next/router';

import { IS_BROWSER } from '@src/config/settings';
import { useRoutes } from '@src/hooks/useRoutes';
import { logger } from '@src/utils/logger';

export interface AppKustomerChatProps {}

/**
 * @name AppKustomerChat
 * @external https://help.kustomer.com/en_us/introduction-kustomer-chat-H1xk1Gb8v Kustomer Chat
 * @external https://kustomer.kustomer.help/chat-sdk-SyRjnij0P Chat Install
 * @description An embeddable chat widget that can be used to communicate
 * with your customers.
 */
export const AppKustomerChat = (_props: AppKustomerChatProps) => {
  // Hooks
  const { pathname } = useRouter();
  const { isActive } = useRoutes();
  const [_started, setStarted] = React.useState(false);

  // Setup
  const kustomer = IS_BROWSER ? (window as any).Kustomer : false;
  const isPathActive = isActive.store || isActive.routine || isActive.summary;
  const isEnabled = kustomer && isPathActive;

  // Handlers
  const onOpenHandler = () => {
    kustomer.createConversation();
  };

  // Life Cycle
  React.useEffect(() => {
    if (!isEnabled) return;

    kustomer.start({ brandId: '5e694c6b7bed1a001927df21' }, function () {
      kustomer.addListener('onOpen', onOpenHandler);
      setStarted(true);
    });
  }, [isEnabled, kustomer, pathname]);

  // // 🚧 👀 kustomer.start() must be called before any other methods
  // React.useEffect(() => {
  //   if (started && !isPathActive) kustomer.close?.();
  // }, [pathname]);

  return (
    <script
      data-kustomer-api-key={process.env.CLIENT_KUSTOMER_CHAT}
      onError={(error) => {
        logger.error({ error }, `🔴 Kustomer Chat error`);
      }}
      src="https://cdn.kustomerapp.com/chat-web/widget.js"
    />
  );
};
