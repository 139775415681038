import * as React from 'react';
import { color } from '@haldi/style-guide';

export interface IconShapeProps {
  className?: string;
  fill?: string;
  height?: number;
  shape?: number;
  stroke?: string;
  strokeWidth?: number;
  width?: number;
}

/**
 * @name IconShape
 * @description We have 9 different shapes built out of SVG paths. These can
 * be scaled, rotated, translated, and filled with a color of your choosing.
 */
export const IconShape = (props: IconShapeProps) => {
  const {
    className,
    fill = color.turmeric,
    height = 80,
    shape,
    stroke,
    strokeWidth,
    width = 80
  } = props;

  /**
   * These paths were taken directly from the files in "/public/icons/shapeXX.svg"
   * which were all exported from a consistent/normalized layer of 80px by 80px
   * so that we can swap the path here.
   */
  const shape01 = `M25.4769 20.1487L35.3787 80L50.8491 78.7749L56.4177 0C56.4177 0 34.758 3.0509 23 4.88259L25.4769 20.1487Z`;
  const shape02 = `M18.0304 25.9412C17.7837 17.9082 18.9348 8.78988 25.3645 3.97176C29.8537 0.617168 35.8311 0.222586 41.4385 0.0417009C46.5362 -0.12274 52.2094 0.041693 55.8929 3.52784C59.2804 6.81666 59.8888 11.8897 60.2342 16.568C61.2921 31.0772 61.7497 45.6028 61.6072 60.1449C61.6072 62.6115 61.5168 65.2014 60.4644 67.4543C59.4147 69.416 57.9399 71.1183 56.1478 72.4368C47.7778 79.2693 35.346 82.8788 26.1784 77.1644C24.1769 76.0095 22.5565 74.2948 21.5165 72.2312C20.5957 70.2168 20.4806 67.9393 20.3819 65.7276L18.6799 28.2844`;
  const shape03 = `M18.9844 9.11716C13.7494 11.7372 9.27054 15.651 5.97254 20.4876C2.67455 25.3242 0.666768 30.9232 0.139622 36.7534C-0.387524 42.5837 0.583442 48.452 2.96043 53.8017C5.33742 59.1514 9.04162 63.8051 13.7217 67.3216C26.2292 76.7067 43.2626 76.5312 58.84 75.4261C63.7167 75.0752 68.8214 74.6016 72.8736 71.9177C81.6447 65.9183 80.8903 52.8845 78.1362 42.6925C74.9962 31.3779 70.1371 20.0106 61.1555 12.4324C52.174 4.85424 38.3508 2.13525 28.5623 8.73107`;
  const shape04 = `M24.5117 77.6376C21.0747 63.3661 22.013 47.5933 23.8324 32.3481C24.8652 23.9736 26.26 15.1302 30.6645 8.62459C35.069 2.11897 43.4015 -0.617691 47.7663 5.14472C49.6038 7.56102 50.4256 10.9807 51.1258 14.3448C55.0135 33.6827 55.6713 53.5311 53.0726 73.084C52.8583 74.8215 52.5502 76.6623 51.6056 77.965C50.7903 78.9754 49.6794 79.7057 48.4285 80.0534C44.6677 81.4365 40.6673 82.0498 36.6649 81.8569C32.6626 81.664 28.7397 80.6687 25.1295 78.9303`;
  const shape05 = `M14.0642 16.9281C13.9411 13.475 14.9707 10.0792 16.9907 7.27591C19.0108 4.47263 21.9064 2.42147 25.2211 1.44571C32.4747 -0.566155 40.1505 -0.476561 47.3551 1.7041C68.7025 7.79375 65.624 33.4557 65.624 33.4557V64.769C65.624 64.769 60.8377 79.9931 40.4003 79.9931C33.8176 80.1418 27.405 77.8938 22.3561 73.6675C19.6562 71.3581 17.5058 68.4754 16.0616 65.2293C14.6174 61.9833 13.9158 58.4558 14.008 54.9042C14.1766 45.2754 14.4238 27.6693 14.0642 16.9281Z`;
  const shape06 = `M42.7799 0H42.9949C45.9011 0 48.258 3.07007 48.258 6.85113V17.9762C48.2311 19.0304 48.4228 20.0785 48.8205 21.0527C49.2183 22.027 49.8133 22.9056 50.567 23.6316L53.3061 26.0554C54.0582 26.7827 54.6522 27.6614 55.0498 28.6353C55.4475 29.6093 55.64 30.6569 55.6151 31.7108V73.1489C55.6151 76.9299 53.2264 80 50.352 80H30.2551C27.3489 80 25 76.9299 25 73.1489V31.4603C25 28.8265 26.1545 26.427 27.9779 25.2878L34.4672 21.2482C35.403 20.5778 36.1633 19.6857 36.6823 18.6495C37.2012 17.6132 37.4631 16.4641 37.4451 15.3019L37.676 6.62489C37.7636 2.99734 40.001 0.0565543 42.7799 0Z`;
  const shape07 = `M59.8487 9.25314C57.3478 3.8053 51.2774 0.723383 45.3211 0.131903C39.3648 -0.459577 33.4189 1.04507 27.6183 2.51858C24.1109 3.41099 20.3545 4.44866 18.0197 7.19853C15.301 10.3635 15.1557 14.9189 15.1453 19.0904L15 67.1144C15 69.968 15.2179 73.3405 17.5942 74.8866C18.7363 75.5331 20.0098 75.9127 21.3195 75.9969L47.2616 79.6081C51.4123 80.1996 56.4036 80.542 59.2676 77.4186C60.8449 75.7064 61.4052 73.2678 61.8411 71.0368C65.6374 51.2415 65.1634 30.8637 60.4506 11.2663`;
  const shape08 = `M28.0219 16.9115C27.9119 9.808 29.9519 3.42382 33.0219 1.42315C35.5619 -0.206609 38.9119 -0.824804 42.9119 1.69289C52.4419 7.77357 51.0819 33.4451 51.0819 33.4451V64.7701C51.0819 64.7701 48.9419 80 39.8019 80C36.1819 80 33.5719 77.1451 31.7319 73.6833C29.2719 69.0525 27.9419 62.0952 28.0019 54.9018C28.0719 45.2693 28.1719 27.6679 28.0219 16.9115Z`;
  const shape09 = `M34.5173 11.4014C33.3443 7.97599 33.9513 3.7688 36.7565 1.43148C39.5618 -0.905848 44.6227 -0.357796 46.1893 2.9064C47.19 5.01 46.6569 7.61331 47.8298 9.62018C48.6501 10.9662 50.0281 11.7963 51.201 12.8441C56.3932 17.4301 56.4342 25.2722 56.0815 32.123L54.0555 71.4868C53.957 73.3889 53.793 75.4522 52.5134 76.8788C51.4225 78.0958 49.741 78.5955 48.1333 78.9501C41.0506 80.5138 33.6839 80.3283 26.6921 78.4101C25.8788 78.2684 25.1338 77.8727 24.5677 77.2818C24.1159 76.5406 23.9256 75.6737 24.0263 74.8155L24.3298 29.5922C24.3298 23.491 25.4043 15.8907 31.2608 13.8838`;
  const shape10 = `M25.9526 76.6202C2.29934 70.4176 0.502785 49.2178 2.69523 38.3159C2.85263 37.5333 2.95149 36.7462 2.96757 35.948C3.06889 30.9181 3.80432 21.8143 6.1826 17.7747C14.3267 2.13408 29.8353 2.21925 36.3565 2.25506L36.4745 2.25571C38.3166 2.26566 40.2482 1.98639 42.0902 2.00169C63.4196 2.17881 74.1753 16.2648 77.4879 24.7355C77.8595 25.6858 78 26.695 78 27.7154V57.071C78 58.4002 77.7562 59.7198 77.1521 60.9038C67.5067 79.8094 39.2552 79.3376 25.9526 76.6202Z`;
  const shape11 = `M0.643067 52.8829C-0.117059 48.5955 -0.768211 43.8816 2.18149 40.2416C3.18289 39.004 4.54748 37.9771 5.61892 36.7759C8.21321 33.8691 8.90329 30.203 10.1252 26.7788C12.6131 19.8056 17.4748 13.5423 22.282 7.38287C23.1226 6.30647 23.9839 5.20669 25.2213 4.40589C26.5678 3.53489 28.2567 3.08248 29.904 2.64828C33.1988 1.77728 36.5843 0.89587 40.0555 1.01027C43.8587 1.13507 47.4232 2.44547 51.1434 3.09027C56.1556 3.95607 62.1277 3.87024 65.2849 7.08904C69.4124 11.2828 65.4276 17.645 67.7547 22.6318C68.5978 24.4518 70.2166 25.9339 71.5942 27.5173C74.1885 30.5073 75.9811 33.8899 77.5792 37.2933C79.0606 40.4523 80.4096 43.7932 79.8856 47.1316C79.569 49.1414 78.5884 51.0316 77.6156 52.914C75.5401 56.8946 73.4647 60.9038 70.4553 64.484C64.7479 71.2856 55.6938 76.2725 45.6695 78.1471C35.6452 80.0217 24.7492 78.7685 15.8093 74.7099C14.1723 73.9663 12.5249 73.0693 11.6792 71.7303C11.2226 71.0101 11.0254 70.1962 10.5273 69.4916C10.0292 68.787 9.25611 68.2358 8.53749 67.6716C3.6784 63.7976 0.78317 58.4105 0.622324 52.9427`;
  const shape12 = `M4.88365 14.3861C21.8751 6.4231 40.0553 3.9815 58.8067 3.99999C61.9114 3.85457 65.0162 4.28419 67.9659 5.26715C78.2032 9.29027 79.9447 22.8394 79.9908 33.9377C80.0553 42.7053 79.9908 51.852 76.1115 59.6855C68.6754 74.4832 48.4681 79.6348 33.0891 73.4938C17.7102 67.3527 7.16884 52.5271 1.89817 36.7953C-0.727956 28.9341 -1.48355 18.5942 5.37201 13.9607`;
  const shape13 = `M14.4887 79.5L24.4034 49.6875L0.496887 33.4688H29.7778L40.2484 1.5L50.9045 33.4688H80L56.0935 49.6875L66.1935 79.5L40.2484 60.4688L14.4887 79.5Z`;

  const paths = [shape01, shape02, shape03, shape04, shape05, shape06, shape07, shape08, shape09, shape10, shape11, shape12, shape13]; // prettier-ignore
  const random = Math.floor(Math.random() * paths.length);
  const index = shape ? shape - 1 : random;
  const path = paths[index];

  return (
    <svg
      className={className}
      fill="none"
      height={height}
      preserveAspectRatio="none"
      stroke={stroke}
      strokeWidth={strokeWidth}
      viewBox="0 0 80 80"
      width={width}
    >
      <path fill={fill} d={path} />
    </svg>
  );
};
