import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

import {
  linkAuth,
  linkCode,
  linkError,
  linkHTTP,
  linkLogger
} from '@src/config/apolloLinks';
import { ApolloClientInstance, GenericJSONObject } from '@src/types';
import { IS_BROWSER } from './settings';

let apolloClient: ApolloClientInstance;
let tokenClient: string | undefined;
let codeClient: string | undefined;

/**
 * @name createApolloClient
 * @description Setup our Apollo Client instance and pay special attention
 * to the use of `link` below and the order, these are essentially middleware
 * actions that we chain together to provide authentication and the headers
 * required for our API calls/fetches
 */
export function createApolloClient(
  code?: string,
  refresh?: string
): ApolloClientInstance {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        CustomerInfoResult: {
          keyFields: ['email']
        }
      }
    }),
    link: from([
      linkAuth(tokenClient, refresh),
      linkCode(typeof code === 'string' ? code : undefined),
      linkError,
      linkLogger,
      linkHTTP()
    ]),
    ssrMode: !IS_BROWSER
  });
}

/**
 * @name initializeApollo
 * @description If your page has Next.js data fetching methods that use
 * Apollo Client, the initial state gets hydrated here
 */
export function initializeApollo(
  initialState?: GenericJSONObject,
  token?: string,
  code?: string,
  refresh?: string
): ApolloClientInstance {
  /**
   * If our token or code has updated we need to recreate our instance
   */
  const isCodeUpdated = code !== codeClient;
  const isTokenUpdated = token !== tokenClient;
  const isRecreate = isCodeUpdated || isTokenUpdated;
  const isReuse = !isRecreate && apolloClient;

  if (isRecreate) codeClient = code;
  if (isRecreate) tokenClient = token;

  const _apolloClient = isReuse
    ? apolloClient
    : createApolloClient(code, refresh);

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
