import { CookieSetOptions } from 'universal-cookie';

import { IS_DEVELOPMENT } from './settings';

export const APOLLO_STATE = 'APOLLO_STATE';

export const DATE_TODAY = new Date();
export const DATE_TEN_YEARS_MS = 1000 * 60 * 60 * 24 * 365 * 10;
export const DATE_TEN_YEARS_EXP = DATE_TODAY.getTime() + DATE_TEN_YEARS_MS;

export const COOKIE_AUTH_REFRESH = 'haldi_refresh';
export const COOKIE_AUTH_TOKEN = 'haldi_auth';
export const COOKIE_BETA = 'haldi_beta';
export const COOKIE_BLACK_FRIDAY = 'haldi_black_friday';
export const COOKIE_DISCLAIMER = 'haldi_cookies';
export const COOKIE_GIFT_CARDS = 'haldi_gift_cards';
export const COOKIE_STORE_NP = 'haldi_store_np';
export const COOKIE_USER_ID = 'haldi_uid';
export const COOKIE_VISITOR = 'haldi_visitor';

export const COOKIE_OPTIONS: CookieSetOptions = {
  expires: new Date(DATE_TEN_YEARS_EXP),
  path: '/',
  secure: !IS_DEVELOPMENT
};

export const DEBUG_MARKERS = IS_DEVELOPMENT
  ? { endColor: 'red', fontSize: '12px', startColor: 'green' }
  : undefined;

export const HEADER_AUTH_KEY = 'authorization';
export const HEADER_AUTH_REFRESH = 'refresh';
export const HEADER_USER_AGENT = 'user-agent';
export const HEADER_USER_ID = 'code';

export const IMAGE_STATIC_URL = 'https://static.haldiskin.com/';
export const IMAGE_DEFAULT_ROUTINE = `/images/svg/default-routine.svg`;

export const PATH_AFTER_FROM_LOGIN = '/profile/summary';
export const PATH_ASSETS = 'https://static.haldiskin.com/';
export const PATH_LOGIN = '/login';
export const PATH_USER_PHOTOS = 'user_profile_photos';

export const SIMRAN_EMAIL_ADDRESS = `simran.kalra@haldi.com`;
export const SIMRAN_EMAIL_LINK = `mailto:${SIMRAN_EMAIL_ADDRESS}`;

export const SITE_DESCRIPTION = `We pair the best stuff on your bathroom shelf with the best products in modern skincare.`;
export const SITE_EMAIL_ADDRESS = `teamhaldi@haldi.com`;
export const SITE_EMAIL_LINK = `mailto:${SITE_EMAIL_ADDRESS}`;
export const SITE_FACEBOOK = 'https://www.facebook.com/haldiskin';
export const SITE_INSTAGRAM = 'https://www.instagram.com/haldiskin';
export const SITE_JOBS_LINK = `https://beautiful-day-816.notion.site/Haldi-jobs-1a89d955612e4c469137948debbcb032`;
export const SITE_SLOGAN = `You’re more than a skin type.`;
export const SITE_SURVEY_PATH = '/surveys/recommendations';
export const SITE_SURVEY_CTA = 'Take the skin quiz';
export const SITE_TITLE = 'Haldi';
export const SITE_URL = 'https://haldi.com';

export const SURVEY_SUGGEST_TEXT = `👉 Suggest a new product... `;

export const URL_PARAM_DRAFT = 'admin_draft';
