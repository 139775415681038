import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { [key: string]: any };
};

export type AddAlgoRoutineResult = {
  readonly __typename?: 'AddAlgoRoutineResult';
  readonly routine_id: Scalars['String'];
};

/** 🗄️ Interface for all the different types of answers to use. */
export type AnswerInterface = {
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
};

export type AuthenticationInput = {
  readonly refresh: Scalars['String'];
};

export type AuthenticationResult = {
  readonly __typename?: 'AuthenticationResult';
  readonly expiresIn: Scalars['String'];
  readonly idToken: Scalars['String'];
  readonly projectId: Scalars['String'];
  readonly refreshToken: Scalars['String'];
  readonly tokenType: Scalars['String'];
  readonly userId: Scalars['String'];
};

export type CartProduct = {
  readonly __typename?: 'CartProduct';
  readonly brand: Scalars['String'];
  readonly image?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
  readonly productId: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly variant: Scalars['String'];
  readonly variantId: Scalars['String'];
};

export type CartProductList = {
  readonly __typename?: 'CartProductList';
  readonly products: ReadonlyArray<CartProduct>;
};

export type CollectionBrand = {
  readonly __typename?: 'CollectionBrand';
  readonly created: Scalars['DateTime'];
  readonly description: Scalars['String'];
  readonly display_name: Scalars['String'];
  /** This is a derivative field, normalized version of the brand name */
  readonly index_name: Scalars['String'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
};

export type CollectionCart = {
  readonly __typename?: 'CollectionCart';
  readonly created: Scalars['DateTime'];
  readonly customer_id: Scalars['String'];
  readonly id: Scalars['String'];
  readonly line_items: ReadonlyArray<CollectionCartLineItem>;
  readonly modified: Scalars['DateTime'];
  readonly purchased_line_items: ReadonlyArray<CollectionCartPurchasedLineItem>;
  readonly shopify_order_id?: Maybe<Scalars['Float']>;
  readonly status: Scalars['String'];
};

export type CollectionCartLineItem = {
  readonly __typename?: 'CollectionCartLineItem';
  readonly price: Scalars['String'];
  readonly product_id: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly shopify_variant_id: Scalars['Float'];
};

export type CollectionCartPurchasedLineItem = {
  readonly __typename?: 'CollectionCartPurchasedLineItem';
  readonly name: Scalars['String'];
  readonly price: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly shopify_product_id: Scalars['Float'];
  readonly shopify_variant_id: Scalars['Float'];
};

export const CollectionCategoryType = {
  Acne: 'acne',
  Body: 'body',
  Cleanser: 'cleanser',
  Exfoliator: 'exfoliator',
  EyeCare: 'eye_care',
  FaceOil: 'face_oil',
  FaceScrub: 'face_scrub',
  Mask: 'mask',
  Moisturizer: 'moisturizer',
  Other: 'other',
  Retinol: 'retinol',
  Serum: 'serum',
  Sunscreen: 'sunscreen',
  TonerHydrator: 'toner_hydrator'
} as const;

export type CollectionCategoryType = typeof CollectionCategoryType[keyof typeof CollectionCategoryType];
export type CollectionCoachNote = {
  readonly __typename?: 'CollectionCoachNote';
  readonly content: Scalars['String'];
  readonly for_admin: Scalars['String'];
  readonly rank: Scalars['Int'];
};

export type CollectionCustomer = {
  readonly __typename?: 'CollectionCustomer';
  readonly active_persona_id: Scalars['String'];
  readonly kustomer_user_id?: Maybe<Scalars['String']>;
  readonly created: Scalars['DateTime'];
  readonly email: Scalars['String'];
  readonly id: Scalars['String'];
  readonly lifecycle?: Maybe<ReadonlyArray<CollectionLifeCycle>>;
  readonly products_purchase_history?: Maybe<ReadonlyArray<CollectionCustomerProductsPurchaseHistory>>;
  readonly modified: Scalars['DateTime'];
  readonly orders?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly referred?: Maybe<CollectionCustomerReferred>;
  readonly shopify_customer_id?: Maybe<Scalars['String']>;
  readonly shopify_customer_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly survey_id?: Maybe<Scalars['String']>;
  readonly user: CollectionCustomerUser;
};

export type CollectionCustomerProductsPurchaseHistory = {
  readonly __typename?: 'CollectionCustomerProductsPurchaseHistory';
  readonly fulfilled: Scalars['Int'];
  readonly product_id: Scalars['String'];
  readonly purchase_history: ReadonlyArray<CollectionCustomerPurchaseHistory>;
  readonly purchased: Scalars['Int'];
  readonly refunded: Scalars['Int'];
  readonly restocked: Scalars['Int'];
};

export type CollectionCustomerPurchaseHistory = {
  readonly __typename?: 'CollectionCustomerPurchaseHistory';
  readonly created: Scalars['DateTime'];
  readonly fulfilled: Scalars['Int'];
  readonly quantity: Scalars['Int'];
  readonly restocked: Scalars['Int'];
  readonly returned: Scalars['Int'];
  readonly shopify_order_id: Scalars['Float'];
  readonly shopify_order_name: Scalars['String'];
  readonly shopify_product_id: Scalars['Float'];
  readonly shopify_variant_id: Scalars['Float'];
  readonly variant?: Maybe<CollectionProductVariant>;
};

export type CollectionCustomerReferred = {
  readonly __typename?: 'CollectionCustomerReferred';
  readonly by_id: Scalars['String'];
  readonly by_name: Scalars['String'];
  readonly discount_code_title?: Maybe<Scalars['String']>;
  readonly influencer_code_title?: Maybe<Scalars['String']>;
  readonly manual_credit?: Maybe<Scalars['Float']>;
  readonly referral?: Maybe<Scalars['String']>;
  readonly referral_url?: Maybe<Scalars['String']>;
  readonly status: CollectionEligibilityStatus;
  readonly to?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly vanity_id?: Maybe<Scalars['String']>;
};

export const CollectionCustomerReferrerStatus = {
  EligibleOther: 'eligible_other',
  InfluencerAffiliate: 'influencer_affiliate',
  InfluencerGifted: 'influencer_gifted',
  InfluencerPaid: 'influencer_paid',
  Overseas: 'overseas',
  PublicRelationship: 'public_relationship',
  Pwi: 'pwi',
  ReferrerNotFound: 'referrer_not_found',
  Refill: 'refill'
} as const;

export type CollectionCustomerReferrerStatus = typeof CollectionCustomerReferrerStatus[keyof typeof CollectionCustomerReferrerStatus];
export type CollectionCustomerUser = {
  readonly __typename?: 'CollectionCustomerUser';
  readonly first_name: Scalars['String'];
  readonly instagram: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly phone?: Maybe<Scalars['String']>;
  readonly photo_url?: Maybe<Scalars['String']>;
  readonly visibility: CollectionVisibility;
  readonly zipcode?: Maybe<Scalars['String']>;
};

export const CollectionEligibilityStatus = {
  Eligible: 'eligible',
  NotEligible: 'not_eligible'
} as const;

export type CollectionEligibilityStatus = typeof CollectionEligibilityStatus[keyof typeof CollectionEligibilityStatus];
export const CollectionInventoryPolicy = {
  Continue: 'continue',
  Deny: 'deny'
} as const;

export type CollectionInventoryPolicy = typeof CollectionInventoryPolicy[keyof typeof CollectionInventoryPolicy];
export const CollectionLifeCycle = {
  AutoPublish: 'auto_publish',
  Customer: 'customer',
  Deactivated: 'deactivated',
  DoNotProcess: 'do_not_process',
  RecommendationsPublished: 'recommendations_published',
  RoutineLead: 'routine_lead',
  SurveyCompleted: 'survey_completed'
} as const;

export type CollectionLifeCycle = typeof CollectionLifeCycle[keyof typeof CollectionLifeCycle];
export const CollectionOffenders = {
  Accutane: 'accutane',
  Alcohol: 'alcohol',
  Benzoyl: 'benzoyl',
  CoconutOil: 'coconut_oil',
  Formaldehyde: 'formaldehyde',
  Fragrance: 'fragrance',
  Hydroquinone: 'hydroquinone',
  Mci: 'mci',
  MineralOil: 'mineral_oil',
  Parabens: 'parabens',
  Prescription: 'prescription',
  Retinol: 'retinol',
  Salicylic: 'salicylic',
  ScrubBeads: 'scrub_beads',
  Sles: 'sles',
  Sls: 'sls',
  WitchHazel: 'witch_hazel'
} as const;

export type CollectionOffenders = typeof CollectionOffenders[keyof typeof CollectionOffenders];
export type CollectionPersona = {
  readonly __typename?: 'CollectionPersona';
  readonly created: Scalars['DateTime'];
  readonly customer_id: Scalars['String'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly orders?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly preferences: CollectionPersonaPreferences;
  readonly profile: CollectionPersonaProfile;
  readonly purchased_product_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly recommendation: CollectionPersonaProfile;
  readonly status: ReadonlyArray<Maybe<CollectionPersonaStatus>>;
  readonly survey: CollectionPersonaSurvey;
};

export type CollectionPersonaPreferences = {
  readonly __typename?: 'CollectionPersonaPreferences';
  readonly brand_filter?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly budget: Scalars['String'];
  readonly budget_adjust: Scalars['String'];
  readonly category_filter?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly city?: Maybe<Scalars['String']>;
  readonly existing_products: CollectionPersonaPreferencesExistingProducts;
  readonly goals: ReadonlyArray<Scalars['String']>;
  readonly manual_requests?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly open_ended: Scalars['String'];
  readonly reason: ReadonlyArray<Scalars['String']>;
  readonly routine_time: Scalars['String'];
  readonly smell_profile: Scalars['String'];
  readonly special_requests: ReadonlyArray<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly undereye_concerns?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type CollectionPersonaPreferencesExistingProduct = {
  readonly __typename?: 'CollectionPersonaPreferencesExistingProduct';
  readonly product_id: Scalars['String'];
  readonly user_category: CollectionCategoryType;
  readonly user_input_name: Scalars['String'];
};

export type CollectionPersonaPreferencesExistingProducts = {
  readonly __typename?: 'CollectionPersonaPreferencesExistingProducts';
  readonly evening: ReadonlyArray<CollectionPersonaPreferencesExistingProduct>;
  readonly morning: ReadonlyArray<CollectionPersonaPreferencesExistingProduct>;
};

export type CollectionPersonaProfile = {
  readonly __typename?: 'CollectionPersonaProfile';
  readonly coach_note?: Maybe<Scalars['String']>;
  readonly coach_notes?: Maybe<ReadonlyArray<CollectionPersonaProfileCoachNotes>>;
  readonly created?: Maybe<Scalars['DateTime']>;
  readonly existing_negative_products?: Maybe<ReadonlyArray<CollectionPersonaProfileNegativeProducts>>;
  readonly product_notes?: Maybe<ReadonlyArray<CollectionPersonaProfileProductNotes>>;
  readonly skintype_customer?: Maybe<Scalars['String']>;
  readonly store_products?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** @deprecated This field will be deprecated */
  readonly product_howto_notes?: Maybe<ReadonlyArray<CollectionPersonaProfileHowToNotes>>;
  /** @deprecated This field will be deprecated */
  readonly purging_note?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly rest_note?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly routines?: Maybe<ReadonlyArray<CollectionPersonaProfileRoutine>>;
};

export type CollectionPersonaProfileCoachNotes = {
  readonly __typename?: 'CollectionPersonaProfileCoachNotes';
  readonly category: Scalars['String'];
  readonly notes: ReadonlyArray<CollectionCoachNote>;
};

export type CollectionPersonaProfileHowToNotes = {
  readonly __typename?: 'CollectionPersonaProfileHowToNotes';
  readonly note: Scalars['String'];
  readonly order: Scalars['Int'];
  readonly product_id: Scalars['String'];
  readonly title: Scalars['String'];
};

export type CollectionPersonaProfileNegativeProductNotes = {
  readonly __typename?: 'CollectionPersonaProfileNegativeProductNotes';
  readonly content: Scalars['String'];
  readonly for_admin: Scalars['String'];
  readonly rank: Scalars['Float'];
};

export type CollectionPersonaProfileNegativeProducts = {
  readonly __typename?: 'CollectionPersonaProfileNegativeProducts';
  readonly brand: Scalars['String'];
  readonly id: Scalars['String'];
  readonly ingredient_offender_classes: ReadonlyArray<CollectionOffenders>;
  readonly ingredient_offender_matches?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
  readonly note: Scalars['String'];
  readonly notes: ReadonlyArray<CollectionPersonaProfileNegativeProductNotes>;
};

export type CollectionPersonaProfileProductNotes = {
  readonly __typename?: 'CollectionPersonaProfileProductNotes';
  readonly id: Scalars['String'];
  readonly note: Scalars['String'];
  readonly user_solves_for: ReadonlyArray<CollectionSolvesFor>;
};

export type CollectionPersonaProfileRoutine = {
  readonly __typename?: 'CollectionPersonaProfileRoutine';
  readonly group: RoutineGroup;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  /** @deprecated This field is deprecated */
  readonly note?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly product_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** @deprecated This field will be deprecated */
  readonly products?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly steps: ReadonlyArray<CollectionPersonaProfileRoutineStep>;
};

export type CollectionPersonaProfileRoutineStep = {
  readonly __typename?: 'CollectionPersonaProfileRoutineStep';
  readonly modifier: ReadonlyArray<StepModifier>;
  /** @deprecated This field is deprecated */
  readonly name?: Maybe<Scalars['String']>;
  /** @deprecated This field is deprecated */
  readonly note?: Maybe<Scalars['String']>;
  readonly relationship: StepRelationship;
  readonly slots: ReadonlyArray<CollectionPersonaProfileRoutineStepSlot>;
};

export type CollectionPersonaProfileRoutineStepSlot = {
  readonly __typename?: 'CollectionPersonaProfileRoutineStepSlot';
  /** @deprecated This field will be deprecated */
  readonly brand?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly id?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly image?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly name?: Maybe<Scalars['String']>;
  /** @deprecated This field will be deprecated */
  readonly note?: Maybe<Scalars['String']>;
  readonly product_id: Scalars['String'];
  /** @deprecated This field will be deprecated */
  readonly variant_id?: Maybe<Scalars['String']>;
};

export const CollectionPersonaStatus = {
  Auto: 'auto',
  Prepped: 'prepped',
  Published: 'published',
  Purchased: 'purchased'
} as const;

export type CollectionPersonaStatus = typeof CollectionPersonaStatus[keyof typeof CollectionPersonaStatus];
export type CollectionPersonaSurvey = {
  readonly __typename?: 'CollectionPersonaSurvey';
  readonly email?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly instagram?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly open_ended: Scalars['String'];
  readonly phone?: Maybe<Scalars['String']>;
  readonly referral?: Maybe<Scalars['String']>;
  readonly referred_by?: Maybe<Scalars['String']>;
  readonly user_photo_url?: Maybe<Scalars['String']>;
  readonly zipcode?: Maybe<Scalars['String']>;
};

export type CollectionProduct = {
  readonly __typename?: 'CollectionProduct';
  readonly active_intensity: Scalars['String'];
  readonly admin_notes: Scalars['String'];
  readonly algo_default_variant_id: Scalars['Float'];
  readonly algo_ingredient_offenders: ReadonlyArray<Scalars['String']>;
  readonly algo_ingredient_status: Scalars['String'];
  readonly brand: Scalars['String'];
  readonly budget: Scalars['String'];
  readonly category: CollectionProductCategory;
  /** Algo only field */
  readonly category_num: Scalars['String'];
  readonly created: Scalars['DateTime'];
  readonly cruelty_free: Scalars['String'];
  readonly description: Scalars['String'];
  readonly display_name?: Maybe<Scalars['String']>;
  readonly essential: Scalars['String'];
  /** Algo only field */
  readonly exceptions: Scalars['String'];
  readonly fragrance: Scalars['String'];
  readonly fuss_level: Scalars['String'];
  readonly gluten_free: Scalars['String'];
  readonly grease_level: Scalars['String'];
  readonly haldi_status: CollectionProductHaldiStatus;
  readonly headline: Scalars['String'];
  readonly how_to: Scalars['String'];
  readonly id: Scalars['String'];
  readonly images: ReadonlyArray<Scalars['String']>;
  readonly ingredient_offenders: ReadonlyArray<Scalars['String']>;
  readonly ingredient_status: Scalars['String'];
  readonly ingredients_raw: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly natural_status: Scalars['String'];
  readonly nut_free: Scalars['String'];
  readonly official_url: Scalars['String'];
  readonly oil_free: Scalars['String'];
  readonly pregnancy_safe: Scalars['String'];
  readonly price: Scalars['Int'];
  readonly rating: Scalars['String'];
  /** Algo only field */
  readonly reason: Scalars['String'];
  readonly recommended_status: Scalars['String'];
  readonly routine_time: Scalars['String'];
  readonly safe_for_sensitive: Scalars['String'];
  readonly shopify_product_id?: Maybe<Scalars['String']>;
  readonly skin_types: ReadonlyArray<Scalars['String']>;
  readonly smell: Scalars['String'];
  readonly solves_for: ReadonlyArray<Scalars['String']>;
  readonly sophistication_level: Scalars['String'];
  readonly source: Scalars['String'];
  readonly sustainability?: Maybe<Scalars['String']>;
  readonly sustainable: Scalars['String'];
  readonly travel_friendly: Scalars['String'];
  readonly variants: ReadonlyArray<CollectionProductVariant>;
  readonly vegan: Scalars['String'];
  readonly verified: Scalars['String'];
  readonly verified_at: Scalars['DateTime'];
  readonly visible: Scalars['String'];
  /** Algo only field */
  readonly weights: Scalars['Int'];
};

export type CollectionProductCategory = {
  readonly __typename?: 'CollectionProductCategory';
  readonly id: CollectionCategoryType;
  readonly subcategories?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export const CollectionProductHaldiSellingStatus = {
  HaldiActive: 'haldi_active',
  HaldiNoRecommend: 'haldi_no_recommend',
  HaldiOutOfStock: 'haldi_out_of_stock'
} as const;

export type CollectionProductHaldiSellingStatus = typeof CollectionProductHaldiSellingStatus[keyof typeof CollectionProductHaldiSellingStatus];
export const CollectionProductHaldiStatus = {
  HaldiActive: 'haldi_active',
  HaldiNoRecommend: 'haldi_no_recommend',
  HaldiOutOfStock: 'haldi_out_of_stock',
  NonHaldi: 'non_haldi'
} as const;

export type CollectionProductHaldiStatus = typeof CollectionProductHaldiStatus[keyof typeof CollectionProductHaldiStatus];
export const CollectionProductStatus = {
  NotProcessed: 'not_processed',
  Processed: 'processed',
  Processing: 'processing'
} as const;

export type CollectionProductStatus = typeof CollectionProductStatus[keyof typeof CollectionProductStatus];
export type CollectionProductVariant = {
  readonly __typename?: 'CollectionProductVariant';
  readonly continue_selling: Scalars['Boolean'];
  readonly created: Scalars['DateTime'];
  readonly long_back_order: Scalars['Boolean'];
  readonly modified: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly price: Scalars['String'];
  readonly quantity: Scalars['Int'];
  readonly shopify_variant_id: Scalars['Float'];
  readonly status: CollectionProductVariantStatus;
  readonly volume: Scalars['String'];
};

export const CollectionProductVariantStatus = {
  BackOrderedLong: 'back_ordered_long',
  BackOrdered: 'back_ordered',
  Ok: 'ok',
  OutOfStock: 'out_of_stock'
} as const;

export type CollectionProductVariantStatus = typeof CollectionProductVariantStatus[keyof typeof CollectionProductVariantStatus];
export type CollectionRawSurvey = {
  readonly __typename?: 'CollectionRawSurvey';
  readonly acne_level?: Maybe<CollectionRawSurveySelectField>;
  readonly acne_type?: Maybe<CollectionRawSurveySelectField>;
  readonly age?: Maybe<CollectionRawSurveyStringField>;
  readonly budget: CollectionRawSurveySelectField;
  readonly created: Scalars['DateTime'];
  readonly customer_id?: Maybe<Scalars['String']>;
  readonly email?: Maybe<CollectionRawSurveyStringField>;
  readonly first_name?: Maybe<CollectionRawSurveyStringField>;
  readonly gender: CollectionRawSurveySelectField;
  readonly goals: CollectionRawSurveySelectField;
  readonly id: Scalars['String'];
  readonly instagram?: Maybe<CollectionRawSurveyStringField>;
  readonly last_name?: Maybe<CollectionRawSurveyStringField>;
  readonly modified: Scalars['DateTime'];
  readonly open_ended?: Maybe<CollectionRawSurveyStringField>;
  readonly password?: Maybe<CollectionRawSurveyStringField>;
  readonly persona_id?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<CollectionRawSurveyStringField>;
  readonly products?: Maybe<CollectionRawSurveyProducts>;
  readonly reason?: Maybe<CollectionRawSurveySelectField>;
  readonly referral?: Maybe<CollectionRawSurveyStringField>;
  readonly referred_by?: Maybe<CollectionRawSurveyStringField>;
  readonly routine_time: CollectionRawSurveySelectField;
  readonly skin_sensitivity_level: CollectionRawSurveySelectField;
  readonly skin_sensitivity_type: CollectionRawSurveySelectField;
  readonly skintype_evening?: Maybe<CollectionRawSurveySelectField>;
  readonly skintype_morning?: Maybe<CollectionRawSurveySelectField>;
  readonly smell_profile: CollectionRawSurveySelectField;
  readonly special_requests?: Maybe<CollectionRawSurveySelectField>;
  readonly undereye_concerns?: Maybe<CollectionRawSurveySelectField>;
  readonly user_photo_url?: Maybe<CollectionRawSurveyStringField>;
  readonly utm_campaign?: Maybe<CollectionRawSurveyStringField>;
  readonly utm_medium?: Maybe<CollectionRawSurveyStringField>;
  readonly utm_source?: Maybe<CollectionRawSurveyStringField>;
  readonly version?: Maybe<Scalars['Int']>;
  readonly zipcode?: Maybe<CollectionRawSurveyStringField>;
};

export type CollectionRawSurveyNumberField = {
  readonly __typename?: 'CollectionRawSurveyNumberField';
  readonly id: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type CollectionRawSurveyProducts = {
  readonly __typename?: 'CollectionRawSurveyProducts';
  readonly morning: CollectionRawSurveyProductsMorning;
  readonly morning_ids?: Maybe<CollectionRawSurveyProductsMorning>;
  readonly evening: CollectionRawSurveyProductsEvening;
  readonly evening_ids?: Maybe<CollectionRawSurveyProductsEvening>;
};

export type CollectionRawSurveyProductsEvening = {
  readonly __typename?: 'CollectionRawSurveyProductsEvening';
  readonly acne_treatment: Scalars['String'];
  readonly cleanser: Scalars['String'];
  readonly exfoliator: Scalars['String'];
  readonly face_oil: Scalars['String'];
  readonly masks: Scalars['String'];
  readonly moisturizer: Scalars['String'];
  readonly other: Scalars['String'];
  readonly serum: Scalars['String'];
  readonly toner_hydrator: Scalars['String'];
};

export type CollectionRawSurveyProductsMorning = {
  readonly __typename?: 'CollectionRawSurveyProductsMorning';
  readonly cleanser: Scalars['String'];
  readonly moisturizer: Scalars['String'];
  readonly other: Scalars['String'];
  readonly serum: Scalars['String'];
  readonly sunscreen: Scalars['String'];
  readonly toner_hydrator: Scalars['String'];
};

export type CollectionRawSurveySelectField = {
  readonly __typename?: 'CollectionRawSurveySelectField';
  readonly id: Scalars['String'];
  readonly options: ReadonlyArray<CollectionRawSurveySelectFieldOption>;
  readonly title: Scalars['String'];
  readonly type: Scalars['String'];
};

export type CollectionRawSurveySelectFieldOption = {
  readonly __typename?: 'CollectionRawSurveySelectFieldOption';
  readonly id: Scalars['String'];
  readonly selected: Scalars['Boolean'];
  readonly title: Scalars['String'];
};

export type CollectionRawSurveyStringField = {
  readonly __typename?: 'CollectionRawSurveyStringField';
  readonly id: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: Scalars['String'];
  readonly value: Scalars['String'];
};

export type CollectionRoutine = {
  readonly __typename?: 'CollectionRoutine';
  readonly created: Scalars['DateTime'];
  readonly customer_id?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly profile: CollectionRoutineProfile;
  readonly recommendation: CollectionRoutineProfile;
  readonly type: CollectionRoutineType;
};

export type CollectionRoutineProfile = {
  readonly __typename?: 'CollectionRoutineProfile';
  readonly product_howto_notes: ReadonlyArray<CollectionRoutineProfileHowToNotes>;
  readonly purging_note: Scalars['String'];
  readonly rest_note: Scalars['String'];
  readonly routines: ReadonlyArray<CollectionRoutineProfileRoutine>;
};

export type CollectionRoutineProfileHowToNotes = {
  readonly __typename?: 'CollectionRoutineProfileHowToNotes';
  readonly note: Scalars['String'];
  readonly order: Scalars['Int'];
  readonly product_id: Scalars['String'];
  readonly title: Scalars['String'];
};

export type CollectionRoutineProfileHowToNotesInput = {
  readonly note: Scalars['String'];
  readonly order: Scalars['Int'];
  readonly product_id: Scalars['String'];
  readonly title: Scalars['String'];
};

export type CollectionRoutineProfileInput = {
  readonly product_howto_notes?: Maybe<ReadonlyArray<CollectionRoutineProfileHowToNotesInput>>;
  readonly purging_note?: Maybe<Scalars['String']>;
  readonly rest_note?: Maybe<Scalars['String']>;
  readonly routines?: Maybe<ReadonlyArray<CollectionRoutineProfileRoutineInput>>;
};

export type CollectionRoutineProfileRoutine = {
  readonly __typename?: 'CollectionRoutineProfileRoutine';
  readonly group: RoutineGroup;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly steps: ReadonlyArray<CollectionRoutineProfileRoutineStep>;
};

export type CollectionRoutineProfileRoutineInput = {
  readonly group: RoutineGroup;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly steps: ReadonlyArray<CollectionRoutineProfileRoutineStepInput>;
};

export type CollectionRoutineProfileRoutineStep = {
  readonly __typename?: 'CollectionRoutineProfileRoutineStep';
  readonly modifier: ReadonlyArray<StepModifier>;
  readonly relationship: StepRelationship;
  readonly slots: ReadonlyArray<CollectionRoutineProfileRoutineStepSlot>;
};

export type CollectionRoutineProfileRoutineStepInput = {
  readonly modifier: ReadonlyArray<StepModifier>;
  readonly relationship: StepRelationship;
  readonly slots: ReadonlyArray<CollectionRoutineProfileRoutineStepSlotInput>;
};

export type CollectionRoutineProfileRoutineStepSlot = {
  readonly __typename?: 'CollectionRoutineProfileRoutineStepSlot';
  readonly product_id: Scalars['String'];
};

export type CollectionRoutineProfileRoutineStepSlotInput = {
  readonly product_id: Scalars['String'];
};

export const CollectionRoutineType = {
  Admin: 'admin',
  Algo: 'algo',
  Customer: 'customer'
} as const;

export type CollectionRoutineType = typeof CollectionRoutineType[keyof typeof CollectionRoutineType];
export type CollectionShopifyOrder = {
  readonly __typename?: 'CollectionShopifyOrder';
  readonly created: Scalars['DateTime'];
  readonly customer_id: Scalars['String'];
  readonly financial_status: Scalars['String'];
  readonly fulfillment_status: Scalars['String'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly persona_id: Scalars['String'];
  readonly processed_status?: Maybe<CollectionShopifyOrderStatus>;
  readonly product_ids: ReadonlyArray<Scalars['String']>;
  readonly shopify_customer_id: Scalars['String'];
  readonly shopify_order: CollectionShopifyOrderShopifyOrder;
};

export type CollectionShopifyOrderShopifyOrder = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrder';
  readonly created_at: Scalars['String'];
  readonly customer: CollectionShopifyOrderShopifyOrderCustomer;
  readonly discount_codes: ReadonlyArray<CollectionShopifyOrderShopifyOrderDiscountCode>;
  readonly financial_status: Scalars['String'];
  readonly fulfillment_status: Scalars['String'];
  readonly id: Scalars['Float'];
  readonly line_items: ReadonlyArray<CollectionShopifyOrderShopifyOrderLineItem>;
  readonly name: Scalars['String'];
  readonly note?: Maybe<Scalars['String']>;
  readonly note_attributes?: Maybe<ReadonlyArray<CollectionShopifyOrderShopifyOrderNoteAttribute>>;
  readonly processed_at: Scalars['String'];
  readonly refunds: ReadonlyArray<CollectionShopifyOrderShopifyOrderRefunds>;
  readonly tags?: Maybe<Scalars['String']>;
  readonly total_discounts: Scalars['String'];
  readonly total_price_usd: Scalars['String'];
};

export type CollectionShopifyOrderShopifyOrderCustomer = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderCustomer';
  readonly email: Scalars['String'];
  readonly id: Scalars['Float'];
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
};

export type CollectionShopifyOrderShopifyOrderDiscountCode = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderDiscountCode';
  readonly code: Scalars['String'];
};

export type CollectionShopifyOrderShopifyOrderLineItem = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderLineItem';
  readonly fulfillable_quantity: Scalars['Int'];
  readonly fulfillment_status: Scalars['String'];
  readonly name: Scalars['String'];
  readonly price: Scalars['String'];
  readonly quantity: Scalars['Float'];
  readonly product_id?: Maybe<Scalars['Float']>;
  readonly variant_id?: Maybe<Scalars['Float']>;
};

export type CollectionShopifyOrderShopifyOrderNoteAttribute = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderNoteAttribute';
  readonly name: Scalars['String'];
  readonly value: Scalars['String'];
};

export type CollectionShopifyOrderShopifyOrderRefunds = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderRefunds';
  readonly refund_line_items: ReadonlyArray<CollectionShopifyOrderShopifyOrderRefundsLineItem>;
  readonly restock: Scalars['Boolean'];
};

export type CollectionShopifyOrderShopifyOrderRefundsLineItem = {
  readonly __typename?: 'CollectionShopifyOrderShopifyOrderRefundsLineItem';
  readonly line_item: CollectionShopifyOrderShopifyOrderLineItem;
  readonly quantity: Scalars['Int'];
};

export const CollectionShopifyOrderStatus = {
  DnpGiftCard: 'dnp_gift_card',
  DnpTestOrder: 'dnp_test_order',
  DnpIgnore: 'dnp_ignore',
  NotProcessed: 'not_processed',
  Processed: 'processed',
  Processing: 'processing'
} as const;

export type CollectionShopifyOrderStatus = typeof CollectionShopifyOrderStatus[keyof typeof CollectionShopifyOrderStatus];
export type CollectionShopifyProduct = {
  readonly __typename?: 'CollectionShopifyProduct';
  readonly created: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly processed_status?: Maybe<CollectionProductStatus>;
  readonly shopify_product: CollectionShopifyProductShopifyProduct;
};

export type CollectionShopifyProductShopifyProduct = {
  readonly __typename?: 'CollectionShopifyProductShopifyProduct';
  readonly id: Scalars['String'];
  readonly variants: ReadonlyArray<CollectionShopifyProductShopifyProductVariant>;
};

export type CollectionShopifyProductShopifyProductVariant = {
  readonly __typename?: 'CollectionShopifyProductShopifyProductVariant';
  readonly id: Scalars['Float'];
  readonly inventory_quantity: Scalars['Int'];
  readonly inventory_policy: CollectionInventoryPolicy;
};

export const CollectionSolvesFor = {
  Acne: 'acne',
  DarkCircles: 'dark_circles',
  DeadCells: 'dead_cells',
  DeepCleaning: 'deep_cleaning',
  DeepCleaningNoStrip: 'deep_cleaning_no_strip',
  DeepHydration: 'deep_hydration',
  Dryness: 'dryness',
  Dullness: 'dullness',
  Firmness: 'firmness',
  Hydration: 'hydration',
  LightMoisturizing: 'light_moisturizing',
  Multivitamin: 'multivitamin',
  Oiliness: 'oiliness',
  Overall: 'overall',
  Patches: 'patches',
  Pores: 'pores',
  Puffiness: 'puffiness',
  Redness: 'redness',
  Sensitivity: 'sensitivity',
  SkinNourish: 'skin_nourish',
  SkinSoothe: 'skin_soothe',
  SkinStrengthen: 'skin_strengthen',
  Spots: 'spots',
  SunProtection: 'sun_protection',
  Texture: 'texture',
  Tone: 'tone',
  Wrinkles: 'wrinkles'
} as const;

export type CollectionSolvesFor = typeof CollectionSolvesFor[keyof typeof CollectionSolvesFor];
export const CollectionSubcategoryType = {
  Acne: 'acne',
  Aging: 'aging',
  Apricot: 'apricot',
  Bakuchiol: 'bakuchiol',
  Balm: 'balm',
  Bar: 'bar',
  Blackheads: 'blackheads',
  Chemical: 'chemical',
  Cream: 'cream',
  DarkCircles: 'dark_circles',
  Dryness: 'dryness',
  Essence: 'essence',
  Exfoliating: 'exfoliating',
  Foot: 'foot',
  Gel: 'gel',
  Ha: 'ha',
  Hand: 'hand',
  Hydration: 'hydration',
  Milk: 'milk',
  Mud: 'mud',
  Natural: 'natural',
  Neck: 'neck',
  NightCream: 'night_cream',
  Oil: 'oil',
  Oral: 'oral',
  Other: 'other',
  Overall: 'overall',
  Overnight: 'overnight',
  Patches: 'patches',
  Peel: 'peel',
  Puffiness: 'puffiness',
  Purifying: 'purifying',
  Redness: 'redness',
  Retinol: 'retinol',
  Scrub: 'scrub',
  Sensitivity: 'sensitivity',
  Serum: 'serum',
  SerumB: 'serum_b',
  Spot: 'spot',
  Sunscreen: 'sunscreen',
  Tinted: 'tinted',
  Tools: 'tools',
  Treatment: 'treatment',
  VitaminC: 'vitamin_c',
  Water: 'water',
  Wipes: 'wipes',
  Wrinkles: 'wrinkles'
} as const;

export type CollectionSubcategoryType = typeof CollectionSubcategoryType[keyof typeof CollectionSubcategoryType];
export type CollectionSurvey = {
  readonly __typename?: 'CollectionSurvey';
  readonly created: Scalars['String'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['String'];
  readonly name: Scalars['String'];
  readonly questions?: Maybe<ReadonlyArray<CollectionSurveyQuestions>>;
  readonly success: Scalars['String'];
  readonly tracking: CollectionSurveyTracking;
  readonly version: Scalars['Int'];
};

/** 🗄️ Union of all the different types of answers to use. */
export type CollectionSurveyAnswer = CollectionSurveyAnswerImage | CollectionSurveyAnswerMultiChoice | CollectionSurveyAnswerNumber | CollectionSurveyAnswerProducts | CollectionSurveyAnswerSingleChoice | CollectionSurveyAnswerText;

/** 🖼️ Answer for an image url. It's really just a string but having a different type allows our UI to display the questions differently. */
export type CollectionSurveyAnswerImage = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerImage';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly value: Scalars['String'];
};

export type CollectionSurveyAnswerMultiChoice = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerMultiChoice';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly values: ReadonlyArray<Scalars['String']>;
};

/** 🔢 Simple integer answer. This can be used things like age, etc. */
export type CollectionSurveyAnswerNumber = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerNumber';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly value: Scalars['Int'];
};

export type CollectionSurveyAnswerProduct = {
  readonly __typename?: 'CollectionSurveyAnswerProduct';
  readonly category: CollectionCategoryType;
  readonly product_id: Scalars['String'];
  readonly text: Scalars['String'];
  readonly time_of_day: RoutineGroup;
};

/** 🛍️ Answer for a list of products. */
export type CollectionSurveyAnswerProducts = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerProducts';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly values: ReadonlyArray<CollectionSurveyAnswerProduct>;
};

/** ✅ Single/Multi choice answers to a map of select menu choices. Ex: benzoyl -> Benzoyl Peroxide. */
export type CollectionSurveyAnswerSingleChoice = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerSingleChoice';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly value: Scalars['String'];
};

/** 🗒️ Simple text answer. This can be used for free form text, email, or hidden values that we don't ask a question for but get saved (like UTM parameters). */
export type CollectionSurveyAnswerText = AnswerInterface & {
  readonly __typename?: 'CollectionSurveyAnswerText';
  readonly question_id: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly value: Scalars['String'];
};

/** 🗄️ A set of answers to a survey. */
export type CollectionSurveyAnswers = {
  readonly __typename?: 'CollectionSurveyAnswers';
  readonly created: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly survey_id: Scalars['String'];
  readonly customer_id?: Maybe<Scalars['String']>;
  readonly answers: ReadonlyArray<CollectionSurveyAnswer>;
};

export type CollectionSurveyQuestionChoice = {
  readonly __typename?: 'CollectionSurveyQuestionChoice';
  readonly value: Scalars['String'];
  readonly key: Scalars['String'];
};

export type CollectionSurveyQuestionSkipRule = {
  readonly __typename?: 'CollectionSurveyQuestionSkipRule';
  readonly question_id: Scalars['String'];
  readonly rule_type: CollectionSurveyQuestionSkipRuleType;
  readonly values?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export const CollectionSurveyQuestionSkipRuleType = {
  Contains: 'contains',
  Equals: 'equals',
  HasContent: 'hasContent',
  HasNot: 'hasNot',
  NotEmpty: 'notEmpty'
} as const;

export type CollectionSurveyQuestionSkipRuleType = typeof CollectionSurveyQuestionSkipRuleType[keyof typeof CollectionSurveyQuestionSkipRuleType];
export const CollectionSurveyQuestionType = {
  Email: 'email',
  Image: 'image',
  MultiChoice: 'multi_choice',
  Number: 'number',
  Products: 'products',
  Progress: 'progress',
  QueryParam: 'query_param',
  SingleChoice: 'single_choice',
  SubQuestions: 'sub_questions',
  Text: 'text',
  Textarea: 'textarea'
} as const;

export type CollectionSurveyQuestionType = typeof CollectionSurveyQuestionType[keyof typeof CollectionSurveyQuestionType];
export type CollectionSurveyQuestions = {
  readonly __typename?: 'CollectionSurveyQuestions';
  readonly category: Scalars['String'];
  readonly created: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly name: Scalars['String'];
  readonly required: Scalars['Boolean'];
  readonly text: Scalars['String'];
  readonly type: CollectionSurveyQuestionType;
  readonly error_message?: Maybe<Scalars['String']>;
  readonly placeholder?: Maybe<Scalars['String']>;
  readonly skip_rules?: Maybe<CollectionSurveyQuestionSkipRule>;
  readonly sub_questions?: Maybe<ReadonlyArray<Maybe<CollectionSurveyQuestions>>>;
  readonly subtext?: Maybe<Scalars['String']>;
  readonly choices?: Maybe<ReadonlyArray<CollectionSurveyQuestionChoice>>;
  readonly choices_min?: Maybe<Scalars['Int']>;
  readonly choices_max?: Maybe<Scalars['Int']>;
  readonly number_min?: Maybe<Scalars['Int']>;
  readonly number_max?: Maybe<Scalars['Int']>;
  readonly properties?: Maybe<Scalars['JSONObject']>;
};

/** 📋 Main survey object for all the questions we could have. */
export type CollectionSurveyTracking = {
  readonly __typename?: 'CollectionSurveyTracking';
  readonly conversion?: Maybe<Scalars['String']>;
};

export type CollectionVanityMapping = {
  readonly __typename?: 'CollectionVanityMapping';
  readonly agency_name: Scalars['String'];
  readonly id: Scalars['String'];
  readonly referral_status: Scalars['String'];
  readonly referred_name: Scalars['String'];
  readonly vanity_id: Scalars['String'];
};

export const CollectionVisibility = {
  Public: 'public',
  Private: 'private'
} as const;

export type CollectionVisibility = typeof CollectionVisibility[keyof typeof CollectionVisibility];
export type CreateAccountInput = {
  readonly email: Scalars['String'];
};

export type CreateAccountResult = {
  readonly __typename?: 'CreateAccountResult';
  readonly status: SucceededFailedResultStatus;
  readonly message: Scalars['String'];
};

export type CreateBrandInput = {
  readonly description: Scalars['String'];
  readonly display: Scalars['String'];
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
};

export type CreateBrandResult = {
  readonly __typename?: 'CreateBrandResult';
  readonly id: Scalars['String'];
  readonly modified: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type CreateSurveyInput = {
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['String'];
  readonly version: Scalars['Int'];
};

export type CustomAttributeSetInput = {
  readonly email?: Maybe<Scalars['String']>;
  readonly attributes: Scalars['JSONObject'];
};

export type CustomAttributeSetResult = {
  readonly __typename?: 'CustomAttributeSetResult';
  readonly status: SucceededFailedResultStatus;
  readonly attributes: Scalars['JSONObject'];
};

export type CustomerCartAddInput = {
  readonly productId: Scalars['String'];
  readonly variantId: Scalars['String'];
};

export type CustomerCartCheckoutInput = {
  readonly products: ReadonlyArray<CustomerCartVariant>;
};

export type CustomerCartCheckoutResult = {
  readonly __typename?: 'CustomerCartCheckoutResult';
  readonly checkoutUrl: Scalars['String'];
};

export type CustomerCartRemoveInput = {
  readonly productId: Scalars['String'];
  readonly removeAll?: Maybe<Scalars['Boolean']>;
  readonly variantId: Scalars['String'];
};

export type CustomerCartVariant = {
  readonly quantity: Scalars['Int'];
  readonly variantId: Scalars['String'];
};

export type CustomerGreetingInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type CustomerGreetingResult = {
  readonly __typename?: 'CustomerGreetingResult';
  readonly firstName: Scalars['String'];
  readonly greeting?: Maybe<Scalars['String']>;
};

export type CustomerInfoResult = {
  readonly __typename?: 'CustomerInfoResult';
  readonly activated: Scalars['Boolean'];
  readonly created: Scalars['Boolean'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly friendsUrl?: Maybe<Scalars['String']>;
  readonly instagram?: Maybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly lifecycle: ReadonlyArray<Maybe<CollectionLifeCycle>>;
  readonly vanityId?: Maybe<Scalars['String']>;
  readonly visibility: CollectionVisibility;
};

export type CustomerInfoUpdateInput = {
  readonly firstName: Scalars['String'];
  readonly instagram: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly vanityId?: Maybe<Scalars['String']>;
  readonly visibility?: Maybe<CollectionVisibility>;
};

export type CustomerPasswordSetInput = {
  readonly password: Scalars['String'];
};

export type CustomerPasswordSetResult = {
  readonly __typename?: 'CustomerPasswordSetResult';
  readonly status: SucceededFailedResultStatus;
};


export type DiscountCodeResult = {
  readonly __typename?: 'DiscountCodeResult';
  readonly title: Scalars['String'];
  readonly amount: Scalars['Float'];
};

export type ExistingProduct = {
  readonly __typename?: 'ExistingProduct';
  readonly brand: Scalars['String'];
  readonly category: CollectionCategoryType;
  readonly categoryName: Scalars['String'];
  readonly id: Scalars['String'];
  readonly ingredients: Scalars['String'];
  readonly name: Scalars['String'];
};

export type FriendReferralBy = {
  readonly __typename?: 'FriendReferralBy';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly status: CollectionEligibilityStatus;
  readonly vanityId: Scalars['String'];
};

export type FriendReferralCount = {
  readonly __typename?: 'FriendReferralCount';
  readonly purchased: Scalars['Float'];
  readonly purchasedPastWeek: Scalars['Float'];
  readonly surveyed: Scalars['Float'];
  readonly surveyedPastWeek: Scalars['Float'];
};

export type FriendReferralCredit = {
  readonly __typename?: 'FriendReferralCredit';
  readonly active: Scalars['Float'];
  readonly earned: Scalars['Float'];
  readonly manual: Scalars['Float'];
  readonly redeemed: Scalars['Float'];
};

export type FriendReferralLast = {
  readonly __typename?: 'FriendReferralLast';
  readonly purchased: Scalars['String'];
  readonly purchasedAt: Scalars['String'];
  readonly surveyed: Scalars['String'];
  readonly surveyedAt: Scalars['String'];
};

export type FriendReferred = {
  readonly __typename?: 'FriendReferred';
  readonly becameCustomerAt: Scalars['String'];
  readonly eligible: Scalars['Boolean'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly surveyCreatedAt: Scalars['String'];
};

export type FriendsManualCreditSetInput = {
  readonly amount: Scalars['Float'];
};

export type FriendsReferralResult = {
  readonly __typename?: 'FriendsReferralResult';
  readonly becameCustomerAt: Scalars['String'];
  readonly by: FriendReferralBy;
  readonly count: FriendReferralCount;
  readonly credit: FriendReferralCredit;
  readonly last: FriendReferralLast;
  readonly referred: ReadonlyArray<FriendReferred>;
  readonly referredBy?: Maybe<FriendReferred>;
};

export type FriendsReferralSetInput = {
  readonly referrerId?: Maybe<Scalars['String']>;
};

export type FriendsReferralSetResult = {
  readonly __typename?: 'FriendsReferralSetResult';
  readonly previous?: Maybe<DiscountCodeResult>;
  readonly referred: DiscountCodeResult;
  readonly referrer?: Maybe<DiscountCodeResult>;
};

export type FriendsVanityMapping = {
  readonly __typename?: 'FriendsVanityMapping';
  readonly agencyName: Scalars['String'];
  readonly referralStatus: Scalars['String'];
  readonly referredName: Scalars['String'];
  readonly vanityId: Scalars['String'];
};

export type FriendsVanityMappingsResult = {
  readonly __typename?: 'FriendsVanityMappingsResult';
  readonly mappings: ReadonlyArray<Maybe<FriendsVanityMapping>>;
};

export type GetAdminStatsResult = {
  readonly __typename?: 'GetAdminStatsResult';
  readonly date: Scalars['String'];
  readonly failed: Scalars['Int'];
  readonly prepped: Scalars['Int'];
  readonly published: Scalars['Int'];
  readonly submitted: Scalars['Int'];
  readonly unprepped: Scalars['Int'];
};

export type GetBrandInput = {
  readonly id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type GetPersonaByIdInput = {
  readonly id: Scalars['String'];
};

export type GetProductsByIdInput = {
  readonly ids: ReadonlyArray<Scalars['String']>;
};

export type GetRoutineInput = {
  readonly customerId: Scalars['String'];
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type GetRoutineInputV2 = {
  readonly customerId: Scalars['String'];
  readonly type: CollectionRoutineType;
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type GetRoutineProduct = {
  readonly __typename?: 'GetRoutineProduct';
  readonly brand: Scalars['String'];
  readonly category: Scalars['String'];
  readonly id: Scalars['String'];
  readonly image?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly subcategories: ReadonlyArray<Scalars['String']>;
};

export type GetRoutineResult = {
  readonly __typename?: 'GetRoutineResult';
  readonly routines: ReadonlyArray<PersonaRoutines>;
};

export type GetRoutineResultV2 = {
  readonly __typename?: 'GetRoutineResultV2';
  readonly modified?: Maybe<Scalars['DateTime']>;
  readonly routines: ReadonlyArray<GetRoutineRoutine>;
};

export type GetRoutineRoutine = {
  readonly __typename?: 'GetRoutineRoutine';
  readonly group: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly steps: ReadonlyArray<GetRoutineStep>;
  readonly type: CollectionRoutineType;
};

export type GetRoutineStep = {
  readonly __typename?: 'GetRoutineStep';
  readonly modifier: ReadonlyArray<Scalars['String']>;
  readonly primary_id: Scalars['String'];
  readonly products: ReadonlyArray<GetRoutineProduct>;
  readonly relationship: Scalars['String'];
};

export type GetShopifyOrderByIdInput = {
  readonly id: Scalars['String'];
};

export type GetShopifyOrderByIdResult = {
  readonly __typename?: 'GetShopifyOrderByIdResult';
  readonly name: Scalars['String'];
};

export type GetSurveyInput = {
  readonly id: Scalars['String'];
};


export type MatchingFriends = {
  readonly __typename?: 'MatchingFriends';
  readonly customerId: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Add a new brand to the brands collection */
  readonly createBrand: CreateBrandResult;
  /** Creates a one time code for an influencer if one doesn't exist. If the code already exists this returns the value. */
  readonly adminCodeInfluencerAdd: DiscountCodeResult;
  /** Updates who referred this customer. When that changes discount codes are updated in Firebase and Shopify. Kustomer is also updated. This can also be used to update the discount code values even if the referrer hasn't changed. */
  readonly friendsReferralSet: FriendsReferralSetResult;
  /** Sets the manual credit for this customer. */
  readonly friendsManualCreditSet: DiscountCodeResult;
  /** Processes a paid order. It is safe to call this on an already processed order. */
  readonly orderProcess: OrderProcessResult;
  /** Adds an array of tags to an order. */
  readonly orderTagsAdd: OrderTagsAddResult;
  /** Removes an array of tags from an order. */
  readonly orderTagsRemove: OrderTagsRemoveResult;
  /** Update note on an order. */
  readonly orderUpdateNote: OrderUpdateNoteResult;
  /** Adds a product to the recommendation of a persona. */
  readonly personaProductAdd: PersonaProductAddResult;
  /** Removes a product in the recommendation of a persona. */
  readonly personaProductRemove: PersonaProductRemoveResult;
  /** Replaces a product on the recommendation of a persona. */
  readonly personaProductReplace: PersonaProductReplaceResult;
  /** Publishes a persona by moving recommendations to the profile. */
  readonly personaPublish: PersonaPublishResult;
  /** Processes a product update. This happens anytime inventory is updated including when an order is placed. It is safe to call this on an already processed product. */
  readonly productProcess: ProductProcessResult;
  /** Updates a product variant. */
  readonly productVariantUpdate: ProductVariantUpdateResult;
  /** Adds an algo-generated routine to firestore */
  readonly addAlgoRoutine: AddAlgoRoutineResult;
  /** Create/update the latest routine for a customer */
  readonly routineUpdate: CollectionRoutine;
  /** Adds a product and variant to the customers cart. */
  readonly customerCartAdd: CartProductList;
  /** Decreases the count of an item in a customer's cart or removes it all together. */
  readonly customerCartRemove: CartProductList;
  /** Updates the customer info and returns the updated values. */
  readonly customerInfoUpdate: CustomerInfoResult;
  /** Attempts to create an account for an email. It will only create an account if the customer already exists in Firestore and they do not already have an account in Firebase Auth. */
  readonly createAccount: CreateAccountResult;
  /** Sets the customer's password. */
  readonly customerPasswordSet: CustomerPasswordSetResult;
  /** Sets a custom attribute on a Kustomer "customer" and returns the updated values. All values are optional and only the set ones will actually be sent to Kustomer. */
  readonly customAttributeSet: CustomAttributeSetResult;
  /** Finds the number of unpurchased products within a customer's active store and updates kustomer with the value */
  readonly updateUnpurchased: UnpurchasedResult;
  /** Create a new survey entry */
  readonly createSurvey?: Maybe<Scalars['Boolean']>;
  /** Submit a survey with products and create a routine */
  readonly surveySubmit: SurveySubmitResult;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationFriendsReferralSetArgs = {
  input: FriendsReferralSetInput;
};


export type MutationFriendsManualCreditSetArgs = {
  input: FriendsManualCreditSetInput;
};


export type MutationOrderProcessArgs = {
  input: OrderProcessInput;
};


export type MutationOrderTagsAddArgs = {
  input: OrderTagsAddInput;
};


export type MutationOrderTagsRemoveArgs = {
  input: OrderTagsRemoveInput;
};


export type MutationOrderUpdateNoteArgs = {
  input: OrderUpdateNoteInput;
};


export type MutationPersonaProductAddArgs = {
  input: PersonaProductAddInput;
};


export type MutationPersonaProductRemoveArgs = {
  input: PersonaProductRemoveInput;
};


export type MutationPersonaProductReplaceArgs = {
  input: PersonaProductReplaceInput;
};


export type MutationPersonaPublishArgs = {
  input: PersonaPublishInput;
};


export type MutationProductProcessArgs = {
  input: ProductProcessInput;
};


export type MutationProductVariantUpdateArgs = {
  input: ProductVariantUpdateInput;
};


export type MutationRoutineUpdateArgs = {
  input: CollectionRoutineProfileInput;
};


export type MutationCustomerCartAddArgs = {
  input: CustomerCartAddInput;
};


export type MutationCustomerCartRemoveArgs = {
  input: CustomerCartRemoveInput;
};


export type MutationCustomerInfoUpdateArgs = {
  input: CustomerInfoUpdateInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCustomerPasswordSetArgs = {
  input: CustomerPasswordSetInput;
};


export type MutationCustomAttributeSetArgs = {
  input: CustomAttributeSetInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationSurveySubmitArgs = {
  input: SurveySubmitInput;
};

export type Offender = {
  readonly __typename?: 'Offender';
  readonly brand: Scalars['String'];
  readonly ingredientName: Scalars['String'];
  readonly ingredientMatch: Scalars['String'];
  readonly ingredientType: CollectionOffenders;
  readonly name: Scalars['String'];
  readonly note: Scalars['String'];
  readonly product_id: Scalars['String'];
};

export type Order = {
  readonly __typename?: 'Order';
  readonly date: Scalars['String'];
  readonly discount: DiscountCodeResult;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly status: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly total: Scalars['Float'];
};

export type OrderHistoryResult = {
  readonly __typename?: 'OrderHistoryResult';
  readonly orders?: Maybe<ReadonlyArray<Order>>;
};

export type OrderProcessInput = {
  readonly orderId: Scalars['String'];
};

export type OrderProcessResult = {
  readonly __typename?: 'OrderProcessResult';
  readonly status: SucceededFailedResultStatus;
};

export type OrderTagsAddInput = {
  readonly id: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type OrderTagsAddResult = {
  readonly __typename?: 'OrderTagsAddResult';
  readonly tags: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type OrderTagsRemoveInput = {
  readonly id: Scalars['String'];
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type OrderTagsRemoveResult = {
  readonly __typename?: 'OrderTagsRemoveResult';
  readonly tags: ReadonlyArray<Maybe<Scalars['String']>>;
};

export type OrderUpdateNoteInput = {
  readonly id: Scalars['String'];
  readonly note: Scalars['String'];
};

export type OrderUpdateNoteResult = {
  readonly __typename?: 'OrderUpdateNoteResult';
  readonly note: Scalars['String'];
};

export type PersonaProductAddInput = {
  readonly note: Scalars['String'];
  readonly personaId: Scalars['String'];
  readonly productId: Scalars['String'];
  readonly solvesFor: Scalars['String'];
  readonly categoryNumber?: Maybe<Scalars['String']>;
  readonly weights?: Maybe<Scalars['Int']>;
};

export type PersonaProductAddResult = {
  readonly __typename?: 'PersonaProductAddResult';
  readonly status: SucceededFailedResultStatus;
};

export type PersonaProductRemoveInput = {
  readonly personaId: Scalars['String'];
  readonly productId: Scalars['String'];
  readonly shouldUpdateRoutines?: Maybe<Scalars['Boolean']>;
};

export type PersonaProductRemoveResult = {
  readonly __typename?: 'PersonaProductRemoveResult';
  readonly status: SucceededFailedResultStatus;
};

export type PersonaProductReplaceInput = {
  readonly currentProductId: Scalars['String'];
  readonly newNote: Scalars['String'];
  readonly newProductId: Scalars['String'];
  readonly newSolvesFor: Scalars['String'];
  readonly personaId: Scalars['String'];
  readonly shouldUpdateRoutines?: Maybe<Scalars['Boolean']>;
};

export type PersonaProductReplaceResult = {
  readonly __typename?: 'PersonaProductReplaceResult';
  readonly status: SucceededFailedResultStatus;
};

export type PersonaProfileOffendersInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type PersonaProfileOffendersResult = {
  readonly __typename?: 'PersonaProfileOffendersResult';
  readonly existingProducts: ReadonlyArray<ExistingProduct>;
  readonly existingProductsCount: Scalars['Int'];
  readonly offenders: ReadonlyArray<Offender>;
};

export type PersonaProfileSkinInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type PersonaProfileSkinResult = {
  readonly __typename?: 'PersonaProfileSkinResult';
  readonly acneLevel?: Maybe<Scalars['String']>;
  readonly acneType?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly budget?: Maybe<Scalars['String']>;
  readonly openEnded?: Maybe<Scalars['String']>;
  /** @deprecated Please use the acneType field */
  readonly proneToAcne?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly routineTime?: Maybe<Scalars['String']>;
  readonly sensitivity?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly skinGoals?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly skinType?: Maybe<Scalars['String']>;
  readonly smell?: Maybe<Scalars['String']>;
};

export type PersonaPublishInput = {
  readonly personaId: Scalars['String'];
};

export type PersonaPublishResult = {
  readonly __typename?: 'PersonaPublishResult';
  readonly status: SucceededFailedResultStatus;
};

export type PersonaRoutines = {
  readonly __typename?: 'PersonaRoutines';
  readonly group: PersonaRoutinesType;
  readonly name: Scalars['String'];
  readonly products: ReadonlyArray<PersonaRoutinesProduct>;
};

export type PersonaRoutinesInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type PersonaRoutinesProduct = {
  readonly __typename?: 'PersonaRoutinesProduct';
  readonly brand: Scalars['String'];
  readonly name: Scalars['String'];
  readonly image?: Maybe<Scalars['String']>;
};

export type PersonaRoutinesResult = {
  readonly __typename?: 'PersonaRoutinesResult';
  readonly routines: ReadonlyArray<PersonaRoutines>;
};

export const PersonaRoutinesType = {
  Evening: 'evening',
  Morning: 'morning',
  Week: 'week'
} as const;

export type PersonaRoutinesType = typeof PersonaRoutinesType[keyof typeof PersonaRoutinesType];
export type PersonaStoreCategoriesInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type PersonaStoreCategoriesResult = {
  readonly __typename?: 'PersonaStoreCategoriesResult';
  readonly categories: ReadonlyArray<PersonaStoreCategory>;
};

export type PersonaStoreCategory = {
  readonly __typename?: 'PersonaStoreCategory';
  readonly category: Scalars['String'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
};

export type PersonaStoreProduct = {
  readonly __typename?: 'PersonaStoreProduct';
  readonly brand: Scalars['String'];
  readonly category: CollectionCategoryType;
  readonly description: Scalars['String'];
  readonly friends: ReadonlyArray<MatchingFriends>;
  readonly guarantee: Scalars['String'];
  readonly headline: Scalars['String'];
  readonly howTo: Scalars['String'];
  readonly id: Scalars['String'];
  readonly image?: Maybe<Scalars['String']>;
  readonly ingredients: Scalars['String'];
  readonly name: Scalars['String'];
  readonly note: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly solvesFor: ReadonlyArray<Scalars['String']>;
  readonly sustainability?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly variants: ReadonlyArray<PersonaStoreProductVariant>;
};

export type PersonaStoreProductVariant = {
  readonly __typename?: 'PersonaStoreProductVariant';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
  readonly status: CollectionProductVariantStatus;
};

export type PersonaStoreProductsInput = {
  readonly viewDraft?: Maybe<Scalars['Boolean']>;
};

export type PersonaStoreProductsResult = {
  readonly __typename?: 'PersonaStoreProductsResult';
  readonly firstName: Scalars['String'];
  readonly products: ReadonlyArray<PersonaStoreProduct>;
};

export type ProductProcessInput = {
  readonly id: Scalars['String'];
};

export type ProductProcessResult = {
  readonly __typename?: 'ProductProcessResult';
  readonly status: SucceededFailedResultStatus;
};

export type ProductVariantUpdateInput = {
  readonly longBackOrder: Scalars['Boolean'];
  readonly milliliters: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
  readonly productId: Scalars['String'];
  readonly shopifyVariantId: Scalars['String'];
};

export type ProductVariantUpdateResult = {
  readonly __typename?: 'ProductVariantUpdateResult';
  readonly longBackOrder: Scalars['Boolean'];
  readonly milliliters: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
};

export type Query = {
  readonly __typename?: 'Query';
  /** Get a single brand from firebase */
  readonly getBrand: CollectionBrand;
  /** Get a basic summary of all brands from our firebase collection */
  readonly getBrands: ReadonlyArray<CollectionBrand>;
  /** Returns a list of mappings for vanity id to referral status. */
  readonly friendsVanityMappings: FriendsVanityMappingsResult;
  /** tbd... */
  readonly getPersonaById?: Maybe<CollectionPersona>;
  /** Gets all products by a list of ids. Any ids not found in the products collection will be ignored. */
  readonly getProductsById: ReadonlyArray<CollectionProduct>;
  /** Gets the latest admin routine for a given customer */
  readonly getRoutineRaw: CollectionRoutine;
  /** Return a specific shopify order based on the ID */
  readonly getShopifyOrderById?: Maybe<GetShopifyOrderByIdResult>;
  /** Query our DB for a variety of useful stats. */
  readonly getAdminStats?: Maybe<GetAdminStatsResult>;
  /** Returns a url from shopify to use to checkout a customer. */
  readonly customerCartCheckout: CustomerCartCheckoutResult;
  /** Returns a list of all products in a customers cart. */
  readonly customerCart: CartProductList;
  /** Returns info about the customer. */
  readonly customerInfo: CustomerInfoResult;
  /** Return a fresh JWT using the a long-lived "refresh" token */
  readonly authentication: AuthenticationResult;
  /** Returns the greeting for the customer made by a skin coach. */
  readonly customerGreeting: CustomerGreetingResult;
  /** Returns a list of orders */
  readonly orderHistory: OrderHistoryResult;
  /** Returns discount code info */
  readonly discountCode: DiscountCodeResult;
  /** Returns the current status this customer has in our referral program */
  readonly friendsReferral: FriendsReferralResult;
  /** Returns ok */
  readonly ok: Scalars['Boolean'];
  /** Returns a list of ingredient offenders */
  readonly personaProfileOffenders: PersonaProfileOffendersResult;
  /** Returns the skin profile of the customers current persona. */
  readonly personaProfileSkin: PersonaProfileSkinResult;
  /** Returns all the categories of all the products in the customer's store. This can return the products from the draft or published version. */
  readonly personaStoreCategories: PersonaStoreCategoriesResult;
  /** Returns all the products in a customer's store. */
  readonly personaStoreProducts: PersonaStoreProductsResult;
  /** Returns all the routines for a customer. */
  readonly personaRoutines: PersonaRoutinesResult;
  /** Returns the routine of the customer code */
  readonly getRoutine: GetRoutineResult;
  /** Returns the full routine of the customer code */
  readonly getRoutineV2: GetRoutineResultV2;
  /** Returns a survey by the ID */
  readonly getSurvey: CollectionSurvey;
};


export type QueryGetBrandArgs = {
  input: GetBrandInput;
};


export type QueryGetPersonaByIdArgs = {
  input: GetPersonaByIdInput;
};


export type QueryGetProductsByIdArgs = {
  input: GetProductsByIdInput;
};


export type QueryGetShopifyOrderByIdArgs = {
  input: GetShopifyOrderByIdInput;
};


export type QueryCustomerCartCheckoutArgs = {
  input: CustomerCartCheckoutInput;
};


export type QueryAuthenticationArgs = {
  input: AuthenticationInput;
};


export type QueryCustomerGreetingArgs = {
  input: CustomerGreetingInput;
};


export type QueryPersonaProfileOffendersArgs = {
  input: PersonaProfileOffendersInput;
};


export type QueryPersonaProfileSkinArgs = {
  input: PersonaProfileSkinInput;
};


export type QueryPersonaStoreCategoriesArgs = {
  input: PersonaStoreCategoriesInput;
};


export type QueryPersonaStoreProductsArgs = {
  input: PersonaStoreProductsInput;
};


export type QueryPersonaRoutinesArgs = {
  input: PersonaRoutinesInput;
};


export type QueryGetRoutineArgs = {
  input: GetRoutineInput;
};


export type QueryGetRoutineV2Args = {
  input: GetRoutineInputV2;
};


export type QueryGetSurveyArgs = {
  input: GetSurveyInput;
};

export const RoutineGroup = {
  Evening: 'evening',
  Morning: 'morning',
  Week: 'week'
} as const;

export type RoutineGroup = typeof RoutineGroup[keyof typeof RoutineGroup];
export const StepModifier = {
  AlternateNightsOnly: 'alternate_nights_only',
  AsNeeded: 'as_needed',
  Sample: 'sample'
} as const;

export type StepModifier = typeof StepModifier[keyof typeof StepModifier];
export const StepRelationship = {
  Na: 'na',
  Or: 'or',
  SkipWhenUsing: 'skip_when_using'
} as const;

export type StepRelationship = typeof StepRelationship[keyof typeof StepRelationship];
/** General status for results that have either succeeded or failed */
export const SucceededFailedResultStatus = {
  Succeeded: 'succeeded',
  Failed: 'failed'
} as const;

export type SucceededFailedResultStatus = typeof SucceededFailedResultStatus[keyof typeof SucceededFailedResultStatus];
export type SurveyAnswerProduct = {
  readonly category: CollectionCategoryType;
  readonly product_id: Scalars['String'];
  readonly text: Scalars['String'];
  readonly time_of_day: RoutineGroup;
};

export type SurveyAnswerProducts = {
  readonly question_id: Scalars['String'];
  readonly values: ReadonlyArray<SurveyAnswerProduct>;
};

export type SurveyAnswerText = {
  readonly question_id: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SurveySubmitInput = {
  readonly survey_id: Scalars['String'];
  readonly email: SurveyAnswerText;
  readonly firstName: SurveyAnswerText;
  readonly lastName: SurveyAnswerText;
  readonly products: SurveyAnswerProducts;
};

export type SurveySubmitResult = {
  readonly __typename?: 'SurveySubmitResult';
  readonly customer_id: Scalars['String'];
  readonly routine: CollectionRoutine;
};

export type UnpurchasedResult = {
  readonly __typename?: 'UnpurchasedResult';
  readonly purchased: Scalars['Int'];
  readonly unpurchased: Scalars['Int'];
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createAccount: (
    { readonly __typename?: 'CreateAccountResult' }
    & Pick<CreateAccountResult, 'message' | 'status'>
  ) }
);

export type CreateSurveyMutationVariables = Exact<{
  input: CreateSurveyInput;
}>;


export type CreateSurveyMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'createSurvey'>
);

export type CustomerInfoFragmentFragment = (
  { readonly __typename?: 'CustomerInfoResult' }
  & Pick<CustomerInfoResult, 'activated' | 'created' | 'email' | 'firstName' | 'friendsUrl' | 'instagram' | 'lastName' | 'lifecycle' | 'vanityId' | 'visibility'>
);

export type FriendReferredFragmentFragment = (
  { readonly __typename: 'FriendReferred' }
  & Pick<FriendReferred, 'becameCustomerAt' | 'eligible' | 'firstName' | 'lastName' | 'surveyCreatedAt'>
);

export type FriendsReferralFragmentFragment = (
  { readonly __typename: 'FriendsReferralResult' }
  & Pick<FriendsReferralResult, 'becameCustomerAt'>
  & { readonly by: (
    { readonly __typename: 'FriendReferralBy' }
    & Pick<FriendReferralBy, 'id' | 'name' | 'status' | 'vanityId'>
  ), readonly count: (
    { readonly __typename: 'FriendReferralCount' }
    & Pick<FriendReferralCount, 'purchased' | 'purchasedPastWeek' | 'surveyed' | 'surveyedPastWeek'>
  ), readonly credit: (
    { readonly __typename: 'FriendReferralCredit' }
    & Pick<FriendReferralCredit, 'active' | 'earned' | 'manual' | 'redeemed'>
  ), readonly last: (
    { readonly __typename: 'FriendReferralLast' }
    & Pick<FriendReferralLast, 'purchased' | 'purchasedAt' | 'surveyed' | 'surveyedAt'>
  ), readonly referred: ReadonlyArray<(
    { readonly __typename?: 'FriendReferred' }
    & FriendReferredFragmentFragment
  )>, readonly referredBy?: Maybe<(
    { readonly __typename?: 'FriendReferred' }
    & FriendReferredFragmentFragment
  )> }
);

export type SurveyQuestionsFragmentFragment = (
  { readonly __typename?: 'CollectionSurveyQuestions' }
  & Pick<CollectionSurveyQuestions, 'category' | 'choices_max' | 'created' | 'error_message' | 'id' | 'modified' | 'name' | 'number_max' | 'number_min' | 'placeholder' | 'properties' | 'required' | 'subtext' | 'text' | 'type'>
  & { readonly choices?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'CollectionSurveyQuestionChoice' }
    & Pick<CollectionSurveyQuestionChoice, 'key' | 'value'>
  )>>, readonly skip_rules?: Maybe<(
    { readonly __typename?: 'CollectionSurveyQuestionSkipRule' }
    & Pick<CollectionSurveyQuestionSkipRule, 'question_id' | 'rule_type' | 'values'>
  )> }
);

export type GetCheckoutQueryVariables = Exact<{
  input: CustomerCartCheckoutInput;
}>;


export type GetCheckoutQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerCartCheckout: (
    { readonly __typename?: 'CustomerCartCheckoutResult' }
    & Pick<CustomerCartCheckoutResult, 'checkoutUrl'>
  ) }
);

export type GetCustomerCartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerCartQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerInfo: (
    { readonly __typename?: 'CustomerInfoResult' }
    & CustomerInfoFragmentFragment
  ), readonly customerCart: (
    { readonly __typename?: 'CartProductList' }
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'CartProduct' }
      & Pick<CartProduct, 'brand' | 'image' | 'name' | 'price' | 'productId' | 'title' | 'quantity' | 'variant' | 'variantId'>
    )> }
  ) }
);

export type GetCustomerGreetingQueryVariables = Exact<{
  input: CustomerGreetingInput;
}>;


export type GetCustomerGreetingQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerGreeting: (
    { readonly __typename?: 'CustomerGreetingResult' }
    & Pick<CustomerGreetingResult, 'firstName' | 'greeting'>
  ) }
);

export type GetCustomerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerInfoQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerInfo: (
    { readonly __typename?: 'CustomerInfoResult' }
    & CustomerInfoFragmentFragment
  ) }
);

export type GetFriendsReferralQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFriendsReferralQuery = (
  { readonly __typename?: 'Query' }
  & { readonly friendsReferral: (
    { readonly __typename?: 'FriendsReferralResult' }
    & FriendsReferralFragmentFragment
  ) }
);

export type GetOkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOkQuery = (
  { readonly __typename?: 'Query' }
  & Pick<Query, 'ok'>
);

export type OrderHistoryOrderFieldsFragment = (
  { readonly __typename?: 'Order' }
  & Pick<Order, 'date' | 'name' | 'status' | 'total'>
  & { readonly discount: (
    { readonly __typename?: 'DiscountCodeResult' }
    & Pick<DiscountCodeResult, 'amount' | 'title'>
  ) }
);

export type OrderHistoryFieldsFragment = (
  { readonly __typename?: 'OrderHistoryResult' }
  & { readonly orders?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Order' }
    & OrderHistoryOrderFieldsFragment
  )>> }
);

export type GetOrderHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrderHistoryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly orderHistory: (
    { readonly __typename?: 'OrderHistoryResult' }
    & OrderHistoryFieldsFragment
  ) }
);

export type GetPersonaProfileSkinQueryVariables = Exact<{
  input: PersonaProfileSkinInput;
}>;


export type GetPersonaProfileSkinQuery = (
  { readonly __typename?: 'Query' }
  & { readonly personaProfileSkin: (
    { readonly __typename?: 'PersonaProfileSkinResult' }
    & Pick<PersonaProfileSkinResult, 'budget' | 'openEnded' | 'proneToAcne' | 'routineTime' | 'sensitivity' | 'skinGoals' | 'skinType' | 'smell'>
  ) }
);

export type GetPersonaRoutinesQueryVariables = Exact<{
  input: PersonaRoutinesInput;
}>;


export type GetPersonaRoutinesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly personaRoutines: (
    { readonly __typename?: 'PersonaRoutinesResult' }
    & { readonly routines: ReadonlyArray<(
      { readonly __typename?: 'PersonaRoutines' }
      & Pick<PersonaRoutines, 'name' | 'group'>
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'PersonaRoutinesProduct' }
        & Pick<PersonaRoutinesProduct, 'brand' | 'name' | 'image'>
      )> }
    )> }
  ) }
);

export type GetPersonaStoreCategoriesQueryVariables = Exact<{
  input: PersonaStoreCategoriesInput;
}>;


export type GetPersonaStoreCategoriesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly personaStoreCategories: (
    { readonly __typename?: 'PersonaStoreCategoriesResult' }
    & { readonly categories: ReadonlyArray<(
      { readonly __typename?: 'PersonaStoreCategory' }
      & Pick<PersonaStoreCategory, 'category' | 'name'>
    )> }
  ) }
);

export type GetPersonaStoreProductsQueryVariables = Exact<{
  input: PersonaStoreProductsInput;
}>;


export type GetPersonaStoreProductsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly personaStoreProducts: (
    { readonly __typename?: 'PersonaStoreProductsResult' }
    & Pick<PersonaStoreProductsResult, 'firstName'>
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'PersonaStoreProduct' }
      & Pick<PersonaStoreProduct, 'description' | 'headline' | 'id' | 'image' | 'name' | 'note' | 'solvesFor' | 'sustainability'>
      & { readonly variants: ReadonlyArray<(
        { readonly __typename?: 'PersonaStoreProductVariant' }
        & Pick<PersonaStoreProductVariant, 'id' | 'name' | 'price'>
      )> }
    )> }
  ) }
);

export type GetRoutineQueryVariables = Exact<{
  input: GetRoutineInput;
}>;


export type GetRoutineQuery = (
  { readonly __typename?: 'Query' }
  & { readonly getRoutine: (
    { readonly __typename?: 'GetRoutineResult' }
    & { readonly routines: ReadonlyArray<(
      { readonly __typename?: 'PersonaRoutines' }
      & Pick<PersonaRoutines, 'name' | 'group'>
      & { readonly products: ReadonlyArray<(
        { readonly __typename?: 'PersonaRoutinesProduct' }
        & Pick<PersonaRoutinesProduct, 'brand' | 'image' | 'name'>
      )> }
    )> }
  ) }
);

export type GetSurveyQueryVariables = Exact<{
  input: GetSurveyInput;
}>;


export type GetSurveyQuery = (
  { readonly __typename?: 'Query' }
  & { readonly getSurvey: (
    { readonly __typename: 'CollectionSurvey' }
    & Pick<CollectionSurvey, 'created' | 'id' | 'modified' | 'name' | 'success' | 'version'>
    & { readonly questions?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'CollectionSurveyQuestions' }
      & { readonly sub_questions?: Maybe<ReadonlyArray<Maybe<(
        { readonly __typename?: 'CollectionSurveyQuestions' }
        & SurveyQuestionsFragmentFragment
      )>>> }
      & SurveyQuestionsFragmentFragment
    )>>, readonly tracking: (
      { readonly __typename?: 'CollectionSurveyTracking' }
      & Pick<CollectionSurveyTracking, 'conversion'>
    ) }
  ) }
);

export type GetProfileAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileAccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerInfo: (
    { readonly __typename?: 'CustomerInfoResult' }
    & CustomerInfoFragmentFragment
  ), readonly orderHistory: (
    { readonly __typename?: 'OrderHistoryResult' }
    & { readonly orders?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'Order' }
      & Pick<Order, 'date' | 'name' | 'status' | 'total'>
      & { readonly discount: (
        { readonly __typename?: 'DiscountCodeResult' }
        & Pick<DiscountCodeResult, 'amount' | 'title'>
      ) }
    )>> }
  ) }
);

export type GetProfileFriendsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileFriendsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerInfo: (
    { readonly __typename?: 'CustomerInfoResult' }
    & CustomerInfoFragmentFragment
  ), readonly discountCode: (
    { readonly __typename?: 'DiscountCodeResult' }
    & Pick<DiscountCodeResult, 'amount' | 'title'>
  ), readonly friendsReferral: (
    { readonly __typename?: 'FriendsReferralResult' }
    & FriendsReferralFragmentFragment
  ) }
);

export type GetProfileSummaryQueryVariables = Exact<{
  inputGreeting: CustomerGreetingInput;
  inputOffenders: PersonaProfileOffendersInput;
  inputProfile: PersonaProfileSkinInput;
}>;


export type GetProfileSummaryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerGreeting: (
    { readonly __typename?: 'CustomerGreetingResult' }
    & Pick<CustomerGreetingResult, 'firstName' | 'greeting'>
  ), readonly personaProfileOffenders: (
    { readonly __typename?: 'PersonaProfileOffendersResult' }
    & { readonly existingProducts: ReadonlyArray<(
      { readonly __typename?: 'ExistingProduct' }
      & Pick<ExistingProduct, 'brand' | 'category' | 'categoryName' | 'id' | 'ingredients' | 'name'>
    )>, readonly offenders: ReadonlyArray<(
      { readonly __typename?: 'Offender' }
      & Pick<Offender, 'brand' | 'ingredientMatch' | 'ingredientName' | 'ingredientType' | 'name' | 'note' | 'product_id'>
    )> }
  ), readonly personaProfileSkin: (
    { readonly __typename?: 'PersonaProfileSkinResult' }
    & Pick<PersonaProfileSkinResult, 'acneLevel' | 'acneType' | 'budget' | 'openEnded' | 'proneToAcne' | 'routineTime' | 'sensitivity' | 'skinGoals' | 'skinType' | 'smell'>
  ) }
);

export type StoreIndexCategoryFieldsFragment = (
  { readonly __typename?: 'PersonaStoreCategory' }
  & Pick<PersonaStoreCategory, 'category' | 'name' | 'priority'>
);

export type StoreIndexProductFieldsFragment = (
  { readonly __typename?: 'PersonaStoreProduct' }
  & Pick<PersonaStoreProduct, 'brand' | 'category' | 'description' | 'guarantee' | 'headline' | 'howTo' | 'id' | 'image' | 'ingredients' | 'name' | 'note' | 'priority' | 'solvesFor' | 'sustainability' | 'title'>
  & { readonly friends: ReadonlyArray<(
    { readonly __typename?: 'MatchingFriends' }
    & Pick<MatchingFriends, 'customerId' | 'firstName' | 'lastName'>
  )>, readonly variants: ReadonlyArray<(
    { readonly __typename?: 'PersonaStoreProductVariant' }
    & Pick<PersonaStoreProductVariant, 'id' | 'name' | 'price' | 'status'>
  )> }
);

export type StoreIndexProductsFieldsFragment = (
  { readonly __typename?: 'PersonaStoreProductsResult' }
  & Pick<PersonaStoreProductsResult, 'firstName'>
  & { readonly products: ReadonlyArray<(
    { readonly __typename?: 'PersonaStoreProduct' }
    & StoreIndexProductFieldsFragment
  )> }
);

export type GetStoreIndexQueryVariables = Exact<{
  inputCategories: PersonaStoreCategoriesInput;
  inputProducts: PersonaStoreProductsInput;
}>;


export type GetStoreIndexQuery = (
  { readonly __typename?: 'Query' }
  & { readonly personaStoreCategories: (
    { readonly __typename?: 'PersonaStoreCategoriesResult' }
    & { readonly categories: ReadonlyArray<(
      { readonly __typename?: 'PersonaStoreCategory' }
      & StoreIndexCategoryFieldsFragment
    )> }
  ), readonly personaStoreProducts: (
    { readonly __typename?: 'PersonaStoreProductsResult' }
    & StoreIndexProductsFieldsFragment
  ) }
);

export type SetCustomerCartAddMutationVariables = Exact<{
  input: CustomerCartAddInput;
}>;


export type SetCustomerCartAddMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly customerCartAdd: (
    { readonly __typename?: 'CartProductList' }
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'CartProduct' }
      & Pick<CartProduct, 'image' | 'name' | 'price' | 'productId' | 'quantity' | 'variant' | 'variantId'>
    )> }
  ) }
);

export type SetCustomerCartRemoveMutationVariables = Exact<{
  input: CustomerCartRemoveInput;
}>;


export type SetCustomerCartRemoveMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly customerCartRemove: (
    { readonly __typename?: 'CartProductList' }
    & { readonly products: ReadonlyArray<(
      { readonly __typename?: 'CartProduct' }
      & Pick<CartProduct, 'image' | 'name' | 'price' | 'productId' | 'quantity' | 'variant' | 'variantId'>
    )> }
  ) }
);

export type SetCustomerInfoMutationVariables = Exact<{
  input: CustomerInfoUpdateInput;
}>;


export type SetCustomerInfoMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly customerInfoUpdate: (
    { readonly __typename?: 'CustomerInfoResult' }
    & Pick<CustomerInfoResult, 'email' | 'firstName' | 'lastName' | 'instagram' | 'visibility'>
  ) }
);

export type SetCustomerPasswordMutationVariables = Exact<{
  input: CustomerPasswordSetInput;
}>;


export type SetCustomerPasswordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly customerPasswordSet: (
    { readonly __typename?: 'CustomerPasswordSetResult' }
    & Pick<CustomerPasswordSetResult, 'status'>
  ) }
);

export type SubmitProductsSurveyMutationVariables = Exact<{
  input: SurveySubmitInput;
}>;


export type SubmitProductsSurveyMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly surveySubmit: (
    { readonly __typename?: 'SurveySubmitResult' }
    & Pick<SurveySubmitResult, 'customer_id'>
  ) }
);

export const CustomerInfoFragmentFragmentDoc = gql`
    fragment CustomerInfoFragment on CustomerInfoResult {
  activated
  created
  email
  firstName
  friendsUrl
  instagram
  lastName
  lifecycle
  vanityId
  visibility
}
    `;
export const FriendReferredFragmentFragmentDoc = gql`
    fragment FriendReferredFragment on FriendReferred {
  __typename
  becameCustomerAt
  eligible
  firstName
  lastName
  surveyCreatedAt
}
    `;
export const FriendsReferralFragmentFragmentDoc = gql`
    fragment FriendsReferralFragment on FriendsReferralResult {
  __typename
  becameCustomerAt
  by {
    __typename
    id
    name
    status
    vanityId
  }
  count {
    __typename
    purchased
    purchasedPastWeek
    surveyed
    surveyedPastWeek
  }
  credit {
    __typename
    active
    earned
    manual
    redeemed
  }
  last {
    __typename
    purchased
    purchasedAt
    surveyed
    surveyedAt
  }
  referred {
    ...FriendReferredFragment
  }
  referredBy {
    ...FriendReferredFragment
  }
}
    ${FriendReferredFragmentFragmentDoc}`;
export const SurveyQuestionsFragmentFragmentDoc = gql`
    fragment SurveyQuestionsFragment on CollectionSurveyQuestions {
  category
  choices {
    key
    value
  }
  choices_max
  choices_max
  created
  error_message
  id
  modified
  name
  number_max
  number_min
  placeholder
  properties
  required
  skip_rules {
    question_id
    rule_type
    values
  }
  subtext
  text
  type
}
    `;
export const OrderHistoryOrderFieldsFragmentDoc = gql`
    fragment OrderHistoryOrderFields on Order {
  date
  discount {
    amount
    title
  }
  name
  status
  total
}
    `;
export const OrderHistoryFieldsFragmentDoc = gql`
    fragment OrderHistoryFields on OrderHistoryResult {
  orders {
    ...OrderHistoryOrderFields
  }
}
    ${OrderHistoryOrderFieldsFragmentDoc}`;
export const StoreIndexCategoryFieldsFragmentDoc = gql`
    fragment StoreIndexCategoryFields on PersonaStoreCategory {
  category
  name
  priority
}
    `;
export const StoreIndexProductFieldsFragmentDoc = gql`
    fragment StoreIndexProductFields on PersonaStoreProduct {
  brand
  category
  description
  friends {
    customerId
    firstName
    lastName
  }
  guarantee
  headline
  howTo
  id
  image
  ingredients
  name
  note
  priority
  solvesFor
  sustainability
  title
  variants {
    id
    name
    price
    status
  }
}
    `;
export const StoreIndexProductsFieldsFragmentDoc = gql`
    fragment StoreIndexProductsFields on PersonaStoreProductsResult {
  firstName
  products {
    ...StoreIndexProductFields
  }
}
    ${StoreIndexProductFieldsFragmentDoc}`;
export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    message
    status
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateSurveyDocument = gql`
    mutation createSurvey($input: CreateSurveyInput!) {
  createSurvey(input: $input)
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const GetCheckoutDocument = gql`
    query getCheckout($input: CustomerCartCheckoutInput!) {
  customerCartCheckout(input: $input) {
    checkoutUrl
  }
}
    `;

/**
 * __useGetCheckoutQuery__
 *
 * To run a query within a React component, call `useGetCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCheckoutQuery(baseOptions: Apollo.QueryHookOptions<GetCheckoutQuery, GetCheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckoutQuery, GetCheckoutQueryVariables>(GetCheckoutDocument, options);
      }
export function useGetCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutQuery, GetCheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckoutQuery, GetCheckoutQueryVariables>(GetCheckoutDocument, options);
        }
export type GetCheckoutQueryHookResult = ReturnType<typeof useGetCheckoutQuery>;
export type GetCheckoutLazyQueryHookResult = ReturnType<typeof useGetCheckoutLazyQuery>;
export type GetCheckoutQueryResult = Apollo.QueryResult<GetCheckoutQuery, GetCheckoutQueryVariables>;
export const GetCustomerCartDocument = gql`
    query getCustomerCart {
  customerInfo {
    ...CustomerInfoFragment
  }
  customerCart {
    products {
      brand
      image
      name
      price
      productId
      title
      quantity
      variant
      variantId
    }
  }
}
    ${CustomerInfoFragmentFragmentDoc}`;

/**
 * __useGetCustomerCartQuery__
 *
 * To run a query within a React component, call `useGetCustomerCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerCartQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerCartQuery, GetCustomerCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerCartQuery, GetCustomerCartQueryVariables>(GetCustomerCartDocument, options);
      }
export function useGetCustomerCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerCartQuery, GetCustomerCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerCartQuery, GetCustomerCartQueryVariables>(GetCustomerCartDocument, options);
        }
export type GetCustomerCartQueryHookResult = ReturnType<typeof useGetCustomerCartQuery>;
export type GetCustomerCartLazyQueryHookResult = ReturnType<typeof useGetCustomerCartLazyQuery>;
export type GetCustomerCartQueryResult = Apollo.QueryResult<GetCustomerCartQuery, GetCustomerCartQueryVariables>;
export const GetCustomerGreetingDocument = gql`
    query getCustomerGreeting($input: CustomerGreetingInput!) {
  customerGreeting(input: $input) {
    firstName
    greeting
  }
}
    `;

/**
 * __useGetCustomerGreetingQuery__
 *
 * To run a query within a React component, call `useGetCustomerGreetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerGreetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerGreetingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerGreetingQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerGreetingQuery, GetCustomerGreetingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerGreetingQuery, GetCustomerGreetingQueryVariables>(GetCustomerGreetingDocument, options);
      }
export function useGetCustomerGreetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerGreetingQuery, GetCustomerGreetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerGreetingQuery, GetCustomerGreetingQueryVariables>(GetCustomerGreetingDocument, options);
        }
export type GetCustomerGreetingQueryHookResult = ReturnType<typeof useGetCustomerGreetingQuery>;
export type GetCustomerGreetingLazyQueryHookResult = ReturnType<typeof useGetCustomerGreetingLazyQuery>;
export type GetCustomerGreetingQueryResult = Apollo.QueryResult<GetCustomerGreetingQuery, GetCustomerGreetingQueryVariables>;
export const GetCustomerInfoDocument = gql`
    query getCustomerInfo {
  customerInfo {
    ...CustomerInfoFragment
  }
}
    ${CustomerInfoFragmentFragmentDoc}`;

/**
 * __useGetCustomerInfoQuery__
 *
 * To run a query within a React component, call `useGetCustomerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerInfoQuery, GetCustomerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerInfoQuery, GetCustomerInfoQueryVariables>(GetCustomerInfoDocument, options);
      }
export function useGetCustomerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInfoQuery, GetCustomerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerInfoQuery, GetCustomerInfoQueryVariables>(GetCustomerInfoDocument, options);
        }
export type GetCustomerInfoQueryHookResult = ReturnType<typeof useGetCustomerInfoQuery>;
export type GetCustomerInfoLazyQueryHookResult = ReturnType<typeof useGetCustomerInfoLazyQuery>;
export type GetCustomerInfoQueryResult = Apollo.QueryResult<GetCustomerInfoQuery, GetCustomerInfoQueryVariables>;
export const GetFriendsReferralDocument = gql`
    query getFriendsReferral {
  friendsReferral {
    ...FriendsReferralFragment
  }
}
    ${FriendsReferralFragmentFragmentDoc}`;

/**
 * __useGetFriendsReferralQuery__
 *
 * To run a query within a React component, call `useGetFriendsReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFriendsReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFriendsReferralQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFriendsReferralQuery(baseOptions?: Apollo.QueryHookOptions<GetFriendsReferralQuery, GetFriendsReferralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFriendsReferralQuery, GetFriendsReferralQueryVariables>(GetFriendsReferralDocument, options);
      }
export function useGetFriendsReferralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFriendsReferralQuery, GetFriendsReferralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFriendsReferralQuery, GetFriendsReferralQueryVariables>(GetFriendsReferralDocument, options);
        }
export type GetFriendsReferralQueryHookResult = ReturnType<typeof useGetFriendsReferralQuery>;
export type GetFriendsReferralLazyQueryHookResult = ReturnType<typeof useGetFriendsReferralLazyQuery>;
export type GetFriendsReferralQueryResult = Apollo.QueryResult<GetFriendsReferralQuery, GetFriendsReferralQueryVariables>;
export const GetOkDocument = gql`
    query getOk {
  ok
}
    `;

/**
 * __useGetOkQuery__
 *
 * To run a query within a React component, call `useGetOkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOkQuery(baseOptions?: Apollo.QueryHookOptions<GetOkQuery, GetOkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOkQuery, GetOkQueryVariables>(GetOkDocument, options);
      }
export function useGetOkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOkQuery, GetOkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOkQuery, GetOkQueryVariables>(GetOkDocument, options);
        }
export type GetOkQueryHookResult = ReturnType<typeof useGetOkQuery>;
export type GetOkLazyQueryHookResult = ReturnType<typeof useGetOkLazyQuery>;
export type GetOkQueryResult = Apollo.QueryResult<GetOkQuery, GetOkQueryVariables>;
export const GetOrderHistoryDocument = gql`
    query getOrderHistory {
  orderHistory {
    ...OrderHistoryFields
  }
}
    ${OrderHistoryFieldsFragmentDoc}`;

/**
 * __useGetOrderHistoryQuery__
 *
 * To run a query within a React component, call `useGetOrderHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>(GetOrderHistoryDocument, options);
      }
export function useGetOrderHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>(GetOrderHistoryDocument, options);
        }
export type GetOrderHistoryQueryHookResult = ReturnType<typeof useGetOrderHistoryQuery>;
export type GetOrderHistoryLazyQueryHookResult = ReturnType<typeof useGetOrderHistoryLazyQuery>;
export type GetOrderHistoryQueryResult = Apollo.QueryResult<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>;
export const GetPersonaProfileSkinDocument = gql`
    query getPersonaProfileSkin($input: PersonaProfileSkinInput!) {
  personaProfileSkin(input: $input) {
    budget
    openEnded
    proneToAcne
    routineTime
    sensitivity
    skinGoals
    skinType
    smell
  }
}
    `;

/**
 * __useGetPersonaProfileSkinQuery__
 *
 * To run a query within a React component, call `useGetPersonaProfileSkinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaProfileSkinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaProfileSkinQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPersonaProfileSkinQuery(baseOptions: Apollo.QueryHookOptions<GetPersonaProfileSkinQuery, GetPersonaProfileSkinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonaProfileSkinQuery, GetPersonaProfileSkinQueryVariables>(GetPersonaProfileSkinDocument, options);
      }
export function useGetPersonaProfileSkinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonaProfileSkinQuery, GetPersonaProfileSkinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonaProfileSkinQuery, GetPersonaProfileSkinQueryVariables>(GetPersonaProfileSkinDocument, options);
        }
export type GetPersonaProfileSkinQueryHookResult = ReturnType<typeof useGetPersonaProfileSkinQuery>;
export type GetPersonaProfileSkinLazyQueryHookResult = ReturnType<typeof useGetPersonaProfileSkinLazyQuery>;
export type GetPersonaProfileSkinQueryResult = Apollo.QueryResult<GetPersonaProfileSkinQuery, GetPersonaProfileSkinQueryVariables>;
export const GetPersonaRoutinesDocument = gql`
    query getPersonaRoutines($input: PersonaRoutinesInput!) {
  personaRoutines(input: $input) {
    routines {
      name
      products {
        brand
        name
        image
      }
      group
    }
  }
}
    `;

/**
 * __useGetPersonaRoutinesQuery__
 *
 * To run a query within a React component, call `useGetPersonaRoutinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaRoutinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaRoutinesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPersonaRoutinesQuery(baseOptions: Apollo.QueryHookOptions<GetPersonaRoutinesQuery, GetPersonaRoutinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonaRoutinesQuery, GetPersonaRoutinesQueryVariables>(GetPersonaRoutinesDocument, options);
      }
export function useGetPersonaRoutinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonaRoutinesQuery, GetPersonaRoutinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonaRoutinesQuery, GetPersonaRoutinesQueryVariables>(GetPersonaRoutinesDocument, options);
        }
export type GetPersonaRoutinesQueryHookResult = ReturnType<typeof useGetPersonaRoutinesQuery>;
export type GetPersonaRoutinesLazyQueryHookResult = ReturnType<typeof useGetPersonaRoutinesLazyQuery>;
export type GetPersonaRoutinesQueryResult = Apollo.QueryResult<GetPersonaRoutinesQuery, GetPersonaRoutinesQueryVariables>;
export const GetPersonaStoreCategoriesDocument = gql`
    query getPersonaStoreCategories($input: PersonaStoreCategoriesInput!) {
  personaStoreCategories(input: $input) {
    categories {
      category
      name
    }
  }
}
    `;

/**
 * __useGetPersonaStoreCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPersonaStoreCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaStoreCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaStoreCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPersonaStoreCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetPersonaStoreCategoriesQuery, GetPersonaStoreCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonaStoreCategoriesQuery, GetPersonaStoreCategoriesQueryVariables>(GetPersonaStoreCategoriesDocument, options);
      }
export function useGetPersonaStoreCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonaStoreCategoriesQuery, GetPersonaStoreCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonaStoreCategoriesQuery, GetPersonaStoreCategoriesQueryVariables>(GetPersonaStoreCategoriesDocument, options);
        }
export type GetPersonaStoreCategoriesQueryHookResult = ReturnType<typeof useGetPersonaStoreCategoriesQuery>;
export type GetPersonaStoreCategoriesLazyQueryHookResult = ReturnType<typeof useGetPersonaStoreCategoriesLazyQuery>;
export type GetPersonaStoreCategoriesQueryResult = Apollo.QueryResult<GetPersonaStoreCategoriesQuery, GetPersonaStoreCategoriesQueryVariables>;
export const GetPersonaStoreProductsDocument = gql`
    query getPersonaStoreProducts($input: PersonaStoreProductsInput!) {
  personaStoreProducts(input: $input) {
    firstName
    products {
      description
      headline
      id
      image
      name
      note
      solvesFor
      sustainability
      variants {
        id
        name
        price
      }
    }
  }
}
    `;

/**
 * __useGetPersonaStoreProductsQuery__
 *
 * To run a query within a React component, call `useGetPersonaStoreProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonaStoreProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonaStoreProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPersonaStoreProductsQuery(baseOptions: Apollo.QueryHookOptions<GetPersonaStoreProductsQuery, GetPersonaStoreProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonaStoreProductsQuery, GetPersonaStoreProductsQueryVariables>(GetPersonaStoreProductsDocument, options);
      }
export function useGetPersonaStoreProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonaStoreProductsQuery, GetPersonaStoreProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonaStoreProductsQuery, GetPersonaStoreProductsQueryVariables>(GetPersonaStoreProductsDocument, options);
        }
export type GetPersonaStoreProductsQueryHookResult = ReturnType<typeof useGetPersonaStoreProductsQuery>;
export type GetPersonaStoreProductsLazyQueryHookResult = ReturnType<typeof useGetPersonaStoreProductsLazyQuery>;
export type GetPersonaStoreProductsQueryResult = Apollo.QueryResult<GetPersonaStoreProductsQuery, GetPersonaStoreProductsQueryVariables>;
export const GetRoutineDocument = gql`
    query getRoutine($input: GetRoutineInput!) {
  getRoutine(input: $input) {
    routines {
      name
      products {
        brand
        image
        name
      }
      group
    }
  }
}
    `;

/**
 * __useGetRoutineQuery__
 *
 * To run a query within a React component, call `useGetRoutineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoutineQuery(baseOptions: Apollo.QueryHookOptions<GetRoutineQuery, GetRoutineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutineQuery, GetRoutineQueryVariables>(GetRoutineDocument, options);
      }
export function useGetRoutineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutineQuery, GetRoutineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutineQuery, GetRoutineQueryVariables>(GetRoutineDocument, options);
        }
export type GetRoutineQueryHookResult = ReturnType<typeof useGetRoutineQuery>;
export type GetRoutineLazyQueryHookResult = ReturnType<typeof useGetRoutineLazyQuery>;
export type GetRoutineQueryResult = Apollo.QueryResult<GetRoutineQuery, GetRoutineQueryVariables>;
export const GetSurveyDocument = gql`
    query getSurvey($input: GetSurveyInput!) {
  getSurvey(input: $input) {
    __typename
    created
    id
    modified
    name
    questions {
      ...SurveyQuestionsFragment
      sub_questions {
        ...SurveyQuestionsFragment
      }
    }
    success
    tracking {
      conversion
    }
    version
  }
}
    ${SurveyQuestionsFragmentFragmentDoc}`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
      }
export function useGetSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
        }
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;
export const GetProfileAccountDocument = gql`
    query getProfileAccount {
  customerInfo {
    ...CustomerInfoFragment
  }
  orderHistory {
    orders {
      date
      discount {
        amount
        title
      }
      name
      status
      total
    }
  }
}
    ${CustomerInfoFragmentFragmentDoc}`;

/**
 * __useGetProfileAccountQuery__
 *
 * To run a query within a React component, call `useGetProfileAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileAccountQuery, GetProfileAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileAccountQuery, GetProfileAccountQueryVariables>(GetProfileAccountDocument, options);
      }
export function useGetProfileAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileAccountQuery, GetProfileAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileAccountQuery, GetProfileAccountQueryVariables>(GetProfileAccountDocument, options);
        }
export type GetProfileAccountQueryHookResult = ReturnType<typeof useGetProfileAccountQuery>;
export type GetProfileAccountLazyQueryHookResult = ReturnType<typeof useGetProfileAccountLazyQuery>;
export type GetProfileAccountQueryResult = Apollo.QueryResult<GetProfileAccountQuery, GetProfileAccountQueryVariables>;
export const GetProfileFriendsDocument = gql`
    query getProfileFriends {
  customerInfo {
    ...CustomerInfoFragment
  }
  discountCode {
    amount
    title
  }
  friendsReferral {
    ...FriendsReferralFragment
  }
}
    ${CustomerInfoFragmentFragmentDoc}
${FriendsReferralFragmentFragmentDoc}`;

/**
 * __useGetProfileFriendsQuery__
 *
 * To run a query within a React component, call `useGetProfileFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFriendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileFriendsQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileFriendsQuery, GetProfileFriendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileFriendsQuery, GetProfileFriendsQueryVariables>(GetProfileFriendsDocument, options);
      }
export function useGetProfileFriendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileFriendsQuery, GetProfileFriendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileFriendsQuery, GetProfileFriendsQueryVariables>(GetProfileFriendsDocument, options);
        }
export type GetProfileFriendsQueryHookResult = ReturnType<typeof useGetProfileFriendsQuery>;
export type GetProfileFriendsLazyQueryHookResult = ReturnType<typeof useGetProfileFriendsLazyQuery>;
export type GetProfileFriendsQueryResult = Apollo.QueryResult<GetProfileFriendsQuery, GetProfileFriendsQueryVariables>;
export const GetProfileSummaryDocument = gql`
    query getProfileSummary($inputGreeting: CustomerGreetingInput!, $inputOffenders: PersonaProfileOffendersInput!, $inputProfile: PersonaProfileSkinInput!) {
  customerGreeting(input: $inputGreeting) {
    firstName
    greeting
  }
  personaProfileOffenders(input: $inputOffenders) {
    existingProducts {
      brand
      category
      categoryName
      id
      ingredients
      name
    }
    offenders {
      brand
      ingredientMatch
      ingredientName
      ingredientType
      name
      note
      product_id
    }
  }
  personaProfileSkin(input: $inputProfile) {
    acneLevel
    acneType
    budget
    openEnded
    proneToAcne
    routineTime
    sensitivity
    skinGoals
    skinType
    smell
  }
}
    `;

/**
 * __useGetProfileSummaryQuery__
 *
 * To run a query within a React component, call `useGetProfileSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileSummaryQuery({
 *   variables: {
 *      inputGreeting: // value for 'inputGreeting'
 *      inputOffenders: // value for 'inputOffenders'
 *      inputProfile: // value for 'inputProfile'
 *   },
 * });
 */
export function useGetProfileSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetProfileSummaryQuery, GetProfileSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileSummaryQuery, GetProfileSummaryQueryVariables>(GetProfileSummaryDocument, options);
      }
export function useGetProfileSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileSummaryQuery, GetProfileSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileSummaryQuery, GetProfileSummaryQueryVariables>(GetProfileSummaryDocument, options);
        }
export type GetProfileSummaryQueryHookResult = ReturnType<typeof useGetProfileSummaryQuery>;
export type GetProfileSummaryLazyQueryHookResult = ReturnType<typeof useGetProfileSummaryLazyQuery>;
export type GetProfileSummaryQueryResult = Apollo.QueryResult<GetProfileSummaryQuery, GetProfileSummaryQueryVariables>;
export const GetStoreIndexDocument = gql`
    query getStoreIndex($inputCategories: PersonaStoreCategoriesInput!, $inputProducts: PersonaStoreProductsInput!) {
  personaStoreCategories(input: $inputCategories) {
    categories {
      ...StoreIndexCategoryFields
    }
  }
  personaStoreProducts(input: $inputProducts) {
    ...StoreIndexProductsFields
  }
}
    ${StoreIndexCategoryFieldsFragmentDoc}
${StoreIndexProductsFieldsFragmentDoc}`;

/**
 * __useGetStoreIndexQuery__
 *
 * To run a query within a React component, call `useGetStoreIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreIndexQuery({
 *   variables: {
 *      inputCategories: // value for 'inputCategories'
 *      inputProducts: // value for 'inputProducts'
 *   },
 * });
 */
export function useGetStoreIndexQuery(baseOptions: Apollo.QueryHookOptions<GetStoreIndexQuery, GetStoreIndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreIndexQuery, GetStoreIndexQueryVariables>(GetStoreIndexDocument, options);
      }
export function useGetStoreIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreIndexQuery, GetStoreIndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreIndexQuery, GetStoreIndexQueryVariables>(GetStoreIndexDocument, options);
        }
export type GetStoreIndexQueryHookResult = ReturnType<typeof useGetStoreIndexQuery>;
export type GetStoreIndexLazyQueryHookResult = ReturnType<typeof useGetStoreIndexLazyQuery>;
export type GetStoreIndexQueryResult = Apollo.QueryResult<GetStoreIndexQuery, GetStoreIndexQueryVariables>;
export const SetCustomerCartAddDocument = gql`
    mutation setCustomerCartAdd($input: CustomerCartAddInput!) {
  customerCartAdd(input: $input) {
    products {
      image
      name
      price
      productId
      quantity
      variant
      variantId
    }
  }
}
    `;
export type SetCustomerCartAddMutationFn = Apollo.MutationFunction<SetCustomerCartAddMutation, SetCustomerCartAddMutationVariables>;

/**
 * __useSetCustomerCartAddMutation__
 *
 * To run a mutation, you first call `useSetCustomerCartAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerCartAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerCartAddMutation, { data, loading, error }] = useSetCustomerCartAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomerCartAddMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerCartAddMutation, SetCustomerCartAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerCartAddMutation, SetCustomerCartAddMutationVariables>(SetCustomerCartAddDocument, options);
      }
export type SetCustomerCartAddMutationHookResult = ReturnType<typeof useSetCustomerCartAddMutation>;
export type SetCustomerCartAddMutationResult = Apollo.MutationResult<SetCustomerCartAddMutation>;
export type SetCustomerCartAddMutationOptions = Apollo.BaseMutationOptions<SetCustomerCartAddMutation, SetCustomerCartAddMutationVariables>;
export const SetCustomerCartRemoveDocument = gql`
    mutation setCustomerCartRemove($input: CustomerCartRemoveInput!) {
  customerCartRemove(input: $input) {
    products {
      image
      name
      price
      productId
      quantity
      variant
      variantId
    }
  }
}
    `;
export type SetCustomerCartRemoveMutationFn = Apollo.MutationFunction<SetCustomerCartRemoveMutation, SetCustomerCartRemoveMutationVariables>;

/**
 * __useSetCustomerCartRemoveMutation__
 *
 * To run a mutation, you first call `useSetCustomerCartRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerCartRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerCartRemoveMutation, { data, loading, error }] = useSetCustomerCartRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomerCartRemoveMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerCartRemoveMutation, SetCustomerCartRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerCartRemoveMutation, SetCustomerCartRemoveMutationVariables>(SetCustomerCartRemoveDocument, options);
      }
export type SetCustomerCartRemoveMutationHookResult = ReturnType<typeof useSetCustomerCartRemoveMutation>;
export type SetCustomerCartRemoveMutationResult = Apollo.MutationResult<SetCustomerCartRemoveMutation>;
export type SetCustomerCartRemoveMutationOptions = Apollo.BaseMutationOptions<SetCustomerCartRemoveMutation, SetCustomerCartRemoveMutationVariables>;
export const SetCustomerInfoDocument = gql`
    mutation setCustomerInfo($input: CustomerInfoUpdateInput!) {
  customerInfoUpdate(input: $input) {
    email
    firstName
    lastName
    instagram
    visibility
  }
}
    `;
export type SetCustomerInfoMutationFn = Apollo.MutationFunction<SetCustomerInfoMutation, SetCustomerInfoMutationVariables>;

/**
 * __useSetCustomerInfoMutation__
 *
 * To run a mutation, you first call `useSetCustomerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerInfoMutation, { data, loading, error }] = useSetCustomerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomerInfoMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerInfoMutation, SetCustomerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerInfoMutation, SetCustomerInfoMutationVariables>(SetCustomerInfoDocument, options);
      }
export type SetCustomerInfoMutationHookResult = ReturnType<typeof useSetCustomerInfoMutation>;
export type SetCustomerInfoMutationResult = Apollo.MutationResult<SetCustomerInfoMutation>;
export type SetCustomerInfoMutationOptions = Apollo.BaseMutationOptions<SetCustomerInfoMutation, SetCustomerInfoMutationVariables>;
export const SetCustomerPasswordDocument = gql`
    mutation setCustomerPassword($input: CustomerPasswordSetInput!) {
  customerPasswordSet(input: $input) {
    status
  }
}
    `;
export type SetCustomerPasswordMutationFn = Apollo.MutationFunction<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>;

/**
 * __useSetCustomerPasswordMutation__
 *
 * To run a mutation, you first call `useSetCustomerPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerPasswordMutation, { data, loading, error }] = useSetCustomerPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomerPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>(SetCustomerPasswordDocument, options);
      }
export type SetCustomerPasswordMutationHookResult = ReturnType<typeof useSetCustomerPasswordMutation>;
export type SetCustomerPasswordMutationResult = Apollo.MutationResult<SetCustomerPasswordMutation>;
export type SetCustomerPasswordMutationOptions = Apollo.BaseMutationOptions<SetCustomerPasswordMutation, SetCustomerPasswordMutationVariables>;
export const SubmitProductsSurveyDocument = gql`
    mutation submitProductsSurvey($input: SurveySubmitInput!) {
  surveySubmit(input: $input) {
    customer_id
  }
}
    `;
export type SubmitProductsSurveyMutationFn = Apollo.MutationFunction<SubmitProductsSurveyMutation, SubmitProductsSurveyMutationVariables>;

/**
 * __useSubmitProductsSurveyMutation__
 *
 * To run a mutation, you first call `useSubmitProductsSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProductsSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProductsSurveyMutation, { data, loading, error }] = useSubmitProductsSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitProductsSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SubmitProductsSurveyMutation, SubmitProductsSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitProductsSurveyMutation, SubmitProductsSurveyMutationVariables>(SubmitProductsSurveyDocument, options);
      }
export type SubmitProductsSurveyMutationHookResult = ReturnType<typeof useSubmitProductsSurveyMutation>;
export type SubmitProductsSurveyMutationResult = Apollo.MutationResult<SubmitProductsSurveyMutation>;
export type SubmitProductsSurveyMutationOptions = Apollo.BaseMutationOptions<SubmitProductsSurveyMutation, SubmitProductsSurveyMutationVariables>;