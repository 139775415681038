import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { parse, stringify } from 'query-string';

import { isExternalLink } from '@src/utils/link';

export interface AppLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

/**
 * @name AppLink
 * @description Wraps the NextJS <Link> component to persist any url parameters
 * to the next page. This is used to pass along the "code" and "draft"
 * parameters.
 */
export const AppLink = (props: AppLinkProps) => {
  const { href, children, ...rest } = props;

  // Hooks
  const { query } = useRouter();

  // Setup
  const {
    category: _category,
    code,
    draft,
    referredBy,
    product: _product,
    slug: _slug, // used for a lot of dynamic pages, like our blog
    ...restParams
  } = query;

  const [pathname, params] = href.split('?');
  const isExternal = isExternalLink(href);

  /**
   * When we link to the survey only, it's an external link but we DO want to
   * pass on who referred them to Haldi if there was a referredBy query param.
   */
  const paramCode = typeof code === 'string' ? code : undefined;
  const paramDraft = typeof draft === 'string' ? draft : undefined;
  const paramReferredBy = typeof referredBy === 'string' ? referredBy : undefined; // prettier-ignore
  const paramsHref = parse(params);
  const merged = { ...paramsHref };

  if (paramCode) merged.code = paramCode;
  if (paramDraft) merged.draft = paramDraft;
  if (paramReferredBy) merged.referredBy = paramReferredBy;

  const search = stringify({ ...restParams, ...merged });
  const url = search !== '' ? `${pathname}?${search}` : pathname;

  // 🔌 Short Circuit
  if (isExternal) {
    return (
      <a {...rest} href={href}>
        {children}
      </a>
    );
  }

  return (
    <Link href={url}>
      <a {...rest}>{children}</a>
    </Link>
  );
};
