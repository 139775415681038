import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import {
  SITE_EMAIL_LINK,
  SITE_FACEBOOK,
  SITE_INSTAGRAM,
  SITE_JOBS_LINK,
  SITE_SURVEY_CTA,
  SITE_SURVEY_PATH
} from '@src/config/constants';
import { CLIENT_SHOPIFY_STORE } from '@src/config/settings';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavLogo } from '@src/services/navigation/components/NavLogo';

import styles from './styles.module.css';

export interface NavFooterProps {}

/**
 * @name NavFooter
 * @description Site-wide footer element
 */
export const NavFooter = (_props: NavFooterProps) => {
  // Hooks
  const { pathname } = useRouter();

  // Styles
  const tailwind = 'flex flex-col sm:flex-row sm:gap-4x';
  const cssReflow = classnames(tailwind, styles.reflow);

  // Setup
  const { white } = color;
  const colors = new Array(10).fill(white);
  const year = new Date().getFullYear();
  const isForgot = pathname.startsWith('/forgot-password');
  const isGifts = pathname.startsWith('/gifts-and-kits');
  const isLogin = pathname.startsWith('/login');
  const isProfile = pathname.startsWith('/profile');
  const isStore = pathname.startsWith('/store');
  const isSurveys = pathname.startsWith('/surveys');

  // Styles
  const cssComponent = classnames('ui-border-top bg-rust', styles.component);

  // Short Circuit
  if (isForgot || isGifts || isLogin || isProfile || isStore || isSurveys) {
    return null;
  }

  return (
    <div className={cssComponent}>
      <footer className="ui-container-xl">
        <div className="flex flex-col gap-4x md:flex-row">
          <div className={styles.logo}>
            <NavLogo colors={colors} />
          </div>

          <div className={cssReflow}>
            <div className={styles.links}>
              <AppLink href="/about-us">Our Story</AppLink>
              <AppLink href="/refer-a-friend">Free Skincare</AppLink>
              <AppLink href={SITE_SURVEY_PATH}>{SITE_SURVEY_CTA}</AppLink>
              <AppLink href={CLIENT_SHOPIFY_STORE}>Gift Cards</AppLink>
              <AppLink href="/faq">FAQs</AppLink>
            </div>

            <div className={styles.links}>
              <a href={SITE_EMAIL_LINK} target="_blank" rel="noreferrer">
                Contact Us
              </a>
              <a href={SITE_JOBS_LINK} target="_blank" rel="noreferrer">
                Jobs
              </a>
              <AppLink href="/legal/terms">Terms of Service</AppLink>
              <AppLink href="/legal/privacy">Privacy Policy</AppLink>
            </div>
          </div>

          <div className={styles.social}>
            <div className="mb-2x flex flex-1 content-between md:mb-4x">
              <a
                className="mr-4x"
                href={SITE_INSTAGRAM}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="View Haldi on Instagram"
                  loading="lazy"
                  src="/images/svg/instagram.svg"
                />
              </a>
              <a
                className="mr-4x"
                href={SITE_FACEBOOK}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="View Haldi on Facebook"
                  loading="lazy"
                  src="/images/svg/facebook.svg"
                />
              </a>
            </div>
            <span className={styles.disclaimer}>
              All Rights Reserved {year}
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};
