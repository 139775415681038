import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { userAtom } from '@src/recoil/atoms/user';
import { NavCart } from '@src/services/navigation/components/NavCart';
import { NavHamburger } from '@src/services/navigation/components/NavHamburger';
import { NavLogo } from '@src/services/navigation/components/NavLogo';

import styles from './navStoreMobile.module.css';

export interface NavStoreMobileProps {
  className?: string;
}

/**
 * @name NavStoreMobile
 * @description Very simple navigation bar we want across the application
 */
export const NavStoreMobile = (props: NavStoreMobileProps) => {
  const { className } = props;

  // Hooks
  const { query } = useRouter();
  const { code } = query;
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const isVisitor = !code && visitor;

  // Styles
  const cssComponent = classnames(`ui-container-lg`, className);
  const cssBtn = 'flex items-center justify-center h-full w-[60px]';
  const cssLogo = classnames('p-2xs', styles.logo);

  return (
    <div className={cssComponent}>
      <div className="flex h-full items-center align-middle">
        <NavHamburger className={cssBtn} />
        <div className="flex-1" />
        <NavLogo className={cssLogo} />
        <div className="flex-1" />
        {isVisitor && <div className={cssBtn} />}
        {!isVisitor && <NavCart className={cssBtn} />}
      </div>
    </div>
  );
};
