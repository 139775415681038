import * as React from 'react';

import { useFirebaseAuth } from '@src/hooks/useFirebaseAuth';
import { trackingDefaults } from '@src/utils/tracking';

export interface AppLifeCycleProps {}

/**
 * @name AppLifeCycle
 * @description We use this component to mount any Application specific
 * logic that we need to initialize 1 time only while still having access to
 * both Apollo and Recoil state.
 *
 * We're using it for mount and un-mount
 */
export const AppLifeCycle = (_props: AppLifeCycleProps) => {
  // Hooks
  useFirebaseAuth();

  // Life Cycle
  React.useEffect(() => {
    trackingDefaults();
  }, []);

  return null;
};
