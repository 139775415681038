import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useFirebase } from '@src/hooks/useFirebase';
import { mobileAtom } from '@src/recoil/atoms/mobile';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface NavMobileStoreProps {}

/**
 * @name NavMobileStore
 * @description This component is similar to our modal, it utilizes fixed
 * positioning and a hamburger like button
 */
export const NavMobileStore = (_props: NavMobileStoreProps) => {
  // Hooks
  const [mobile, setMobile] = useRecoilState(mobileAtom);
  const { data } = useGetCustomerInfoQuery();
  const { pathname } = useRouter();
  const { signOut } = useFirebase();
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const { open } = mobile;
  const created = data?.customerInfo.created ?? false;
  const linkMyAccount = visitor ? '/login' : '/profile/account';

  // Styles
  const tailwind = `flex flex-col ui-mobile gap-3xs mt-10x`;
  const cssContent = classnames(tailwind, styles.content);
  const cssComponent = classnames('px-3xs py-5x', styles.component, {
    [styles.open]: open
  });

  // Handlers
  const onClickClose = () => {
    setMobile((state) => ({ ...state, open: false }));
  };

  const onClickLogout = async () => {
    signOut();
    onClickClose();
  };

  const getClasses = (path: string, uppercase = false) => {
    return classnames(styles.link, {
      [styles.active]: pathname === path,
      [styles.uppercase]: uppercase
    });
  };

  // Markup
  const renderedMyAccount = (
    <AppLink
      className={getClasses('/profile/account')}
      href={linkMyAccount}
      onClick={onClickClose}
    >
      My account
    </AppLink>
  );

  return (
    <div className={cssComponent}>
      <button className={styles.close} onClick={onClickClose}>
        <img alt="Close navigation" src="/images/svg/close.svg" />
      </button>

      <div className={cssContent}>
        <AppLink
          className={getClasses('/profile/routine', true)}
          onClick={onClickClose}
          href="/profile/routine"
        >
          My routine
        </AppLink>
        <AppLink
          className={getClasses('/store', true)}
          href="/store"
          onClick={onClickClose}
        >
          My store
        </AppLink>
        <AppLink
          className={getClasses('/profile/friends', true)}
          href="/profile/friends"
          onClick={onClickClose}
        >
          My friends
        </AppLink>
        <hr className="my-2x" />
        <AppLink
          className={getClasses('/profile/summary')}
          href="/profile/summary"
          onClick={onClickClose}
        >
          My skin profile
        </AppLink>
        {created && renderedMyAccount}
        <button className={styles.link} onClick={onClickLogout} type="button">
          Log out
        </button>
      </div>
    </div>
  );
};
