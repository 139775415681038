import { FirebaseOptions, getApps, initializeApp } from 'firebase/app';

import {
  CLIENT_FIREBASE_API_KEY,
  CLIENT_FIREBASE_AUTH_DOMAIN,
  FIREBASE_APP,
  FIREBASE_STORAGE_BUCKET
} from './settings';

/**
 * @description
 * The only value needed in the Firebase for using email/password auth
 * is the `apiKey` property.
 */
const configFirebase: FirebaseOptions = {
  apiKey: CLIENT_FIREBASE_API_KEY,
  authDomain: CLIENT_FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_APP,
  storageBucket: FIREBASE_STORAGE_BUCKET
};

/**
 * @name initializeFirebase
 * @description Because we have this in _app.tsx it gets called for
 * every page which is good in case they start on a route that is not '/'.
 * But Firebase will error if we initialize all over again.
 * Unsure why this happens since this function is only called in one place
 * when I log it but it does...???
 */
const initializeFirebase = (): void => {
  const apps = getApps();

  if (!apps.length) initializeApp(configFirebase);
};

export { initializeFirebase };
