import * as React from 'react';
import classnames from 'classnames';

import { PATH_ASSETS } from '@src/config/constants';
import { CartProduct } from '@src/generated/hooks';

import styles from './styles.module.css';

export interface DropdownCartItemProps {
  data: CartProduct;
}

/**
 * @name DropdownCartItem
 * @description Simple component preview of a Product in our users cart
 */
export const DropdownCartItem = (props: DropdownCartItemProps) => {
  const { data } = props;

  // Setup
  const { image, name, price, quantity, variant } = data;
  const amount = price.toFixed(2);
  const src = `${PATH_ASSETS}${image}`;

  // Styles
  const cssComponent = classnames('flex', styles.component);

  // Markup
  const renderImage = () => {
    if (!image) return null;

    return (
      <div className={styles.image}>
        <img alt={name} src={src} />
      </div>
    );
  };

  return (
    <div className={cssComponent}>
      {renderImage()}
      <div>
        <p>
          {name} - {variant}
        </p>
        <p className="font-bold">
          ${amount} x{quantity}
        </p>
      </div>
    </div>
  );
};
