import * as React from 'react';

import { DropdownCartItem } from '@src/components/DropdownCartItem';
import { CartProduct } from '@src/generated/hooks';

export interface DropdownCartItemsProps {
  data: readonly CartProduct[];
}

/**
 * @name DropdownCartItems
 * @description Simple component for mapping over a list of cart items
 * and rendering out the preview component(s)
 */
export const DropdownCartItems = (props: DropdownCartItemsProps) => {
  const { data } = props;

  // Markup
  const renderCartItem = (product: CartProduct) => {
    const { productId, variantId } = product;
    const key = `${productId}-${variantId}`;

    return <DropdownCartItem data={product} key={key} />;
  };

  return <>{data.map(renderCartItem)}</>;
};
