import * as React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';

import { DropdownCart } from '@src/components/DropdownCart';
import {
  GetCustomerCartQuery as Query,
  GetCustomerCartQueryVariables as Variables
} from '@src/generated/hooks';
import * as getCustomerCart from '@src/graphql/getCustomerCart.graphql';
import { useRoutes } from '@src/hooks/useRoutes';
import { AppDropdown } from '@src/services/app/components/AppDropdown';
import { getProductTotals } from '@src/services/navigation/utils';

import styles from './styles.module.css';

export interface NavCartProps {
  className?: string;
}

/**
 * @name NavCart
 * @description Shows the users current cart count and reveals the truncated
 * list of their current cart on hover
 */
export const NavCart = (props: NavCartProps) => {
  const { className } = props;

  // Hooks
  const response = useQuery<Query, Variables>(getCustomerCart);
  const { isActive } = useRoutes();

  // Setup
  const { data } = response;
  const products = data?.customerCart.products ?? [];
  const quantity = products.reduce(getProductTotals, 0);

  // Setup
  const isForest = isActive.homepage || isActive['refer-a-friend'];
  const img = isForest ? 'bag-turmeric' : 'bag';
  const src = `/images/svg/${img}.svg`;

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssCounter = classnames('ml-1xs text-16', styles.counter, {
    'text-turmeric': isForest
  });

  // Markup
  const Content = (props: any) => {
    const classes = classnames(props.className, cssComponent);

    return (
      <div {...props} className={classes}>
        <img alt="Your Bag" className={styles.bag} src={src} />
        <div className={cssCounter}>
          (<span>{quantity}</span>)
        </div>
      </div>
    );
  };

  return <AppDropdown content={Content} dropdown={DropdownCart} />;
};
