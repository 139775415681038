import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SITE_SURVEY_CTA, SITE_SURVEY_PATH } from '@src/config/constants';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { mobileAtom } from '@src/recoil/atoms/mobile';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface NavMobileAcquisitionProps {}

/**
 * @name NavMobileAcquisition
 * @description This component is similar to our modal, it utilizes fixed
 * positioning and a hamburger like button
 */
export const NavMobileAcquisition = (_props: NavMobileAcquisitionProps) => {
  // Hooks
  const [mobile, setMobile] = useRecoilState(mobileAtom);
  const { data } = useGetCustomerInfoQuery();
  const { pathname, query } = useRouter();
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const { code } = query;
  const { open } = mobile;
  const isVisitor = !code && visitor;

  // Styles
  const tailwind = `flex flex-col ui-mobile gap-3xs mt-10x`;
  const cssContent = classnames(tailwind, styles.content);
  const cssComponent = classnames('px-3xs py-5x', styles.component, {
    [styles.open]: open
  });

  // Handlers
  const onClickClose = () => {
    setMobile((state) => ({ ...state, open: false }));
  };

  const getClasses = (path: string, uppercase = false) => {
    return classnames(styles.link, {
      [styles.active]: pathname === path,
      [styles.uppercase]: uppercase
    });
  };

  // Markup
  const renderLogin = () => {
    if (!isVisitor) return null;

    return (
      <AppLink
        className={getClasses('/login', true)}
        href="/login"
        onClick={onClickClose}
      >
        Log In
      </AppLink>
    );
  };

  const renderRoutine = () => {
    if (!isVisitor) return null;

    return (
      <HaldiButton
        bordersX="forest"
        bordersY="forest"
        className="mt-2x bg-white"
        href={SITE_SURVEY_PATH}
        onClick={onClickClose}
        thick={true}
      >
        {SITE_SURVEY_CTA}
      </HaldiButton>
    );
  };

  const renderUserProfile = () => {
    if (isVisitor) return null;

    return (
      <HaldiButton
        bordersX="forest"
        bordersY="forest"
        className="mt-2x bg-white"
        href="/profile/summary"
        onClick={onClickClose}
        thick={true}
      >
        {data?.customerInfo.firstName}
      </HaldiButton>
    );
  };

  return (
    <div className={cssComponent}>
      <button className={styles.close} onClick={onClickClose}>
        <img alt="Close navigation" src="/images/svg/close.svg" />
      </button>
      <div className={cssContent}>
        <AppLink
          className={getClasses('/about-us', true)}
          href="/about-us"
          onClick={onClickClose}
        >
          Our story
        </AppLink>
        <AppLink
          className={getClasses('/refer-a-friend', true)}
          href="/refer-a-friend"
          onClick={onClickClose}
        >
          Free Skincare
        </AppLink>
        {renderLogin()}
        {renderUserProfile()}
        {renderRoutine()}
      </div>
    </div>
  );
};
