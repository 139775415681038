import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  NextOrObserver,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  onIdTokenChanged as firebaseOnIdTokenChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  Unsubscribe,
  updateProfile,
  User
} from 'firebase/auth';
import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes
} from 'firebase/storage';
import get from 'lodash/get';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  COOKIE_OPTIONS,
  PATH_USER_PHOTOS
} from '@src/config/constants';
import { initializeFirebase } from '@src/config/firebase';
import { CLIENT_BASE_URL, IS_EMULATOR } from '@src/config/settings';
import {
  CreateAccountDocument,
  CreateAccountMutation,
  CreateAccountMutationVariables
} from '@src/generated/hooks';
import { userAtom, userDefault } from '@src/recoil/atoms/user';
import { getFileExtension } from '@src/utils/strings';

type FirebaseInterface = {
  getEmail: () => string;
  onAuthStateChanged: (handler: NextOrObserver<User>) => Unsubscribe;
  onIdTokenChanged: (handler: NextOrObserver<User>) => Unsubscribe;
  resetPassword: (email?: string) => void;
  signIn: (username: string, password: string, path?: string) => Promise<void>;
  signOut: () => void;
  signUp: (username: string, password: string, path?: string) => Promise<void>;
  updateName: (firstName: string, lastName: string) => Promise<void>;
  uploadPhoto: (file: File) => Promise<string>;
};

/**
 * @name useFirebase
 * @external https://firebase.google.com/docs/reference/js/v9/auth.md
 * @description Simple hook to help ensure that we only initialize our
 * Firebase connection once in the client application.
 */
export const useFirebase = (): FirebaseInterface => {
  // Initialize
  useMemo(() => initializeFirebase(), []);

  // Hooks
  const router = useRouter();
  const [user, setUser] = useRecoilState(userAtom);
  const [cookies, _setCookie, removeCookie] = useCookies([
    COOKIE_AUTH_REFRESH,
    COOKIE_AUTH_TOKEN
  ]);
  const [createAccount] = useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument); // prettier-ignore

  // Setup
  const _token = cookies[COOKIE_AUTH_TOKEN];
  const auth = getAuth();
  const storage = getStorage();
  const config = auth.emulatorConfig;

  if (IS_EMULATOR && config?.host !== '0.0.0.0') {
    connectAuthEmulator(auth, 'http://0.0.0.0:3041', { disableWarnings: true });
    connectStorageEmulator(storage, '0.0.0.0', 3050);
  }

  /**
   * @name getEmail
   * @description Returns the email for the current user. If the user is not
   * logged in then this will return an empty string.
   */
  const getEmail = (): string => {
    try {
      return auth.currentUser?.email ?? '';
    } catch (e) {
      const message = `Error retrieving the email for user ${auth.currentUser?.uid}`;
      Sentry.captureException(new Error(message));

      return '';
    }
  };

  /**
   * @name uploadPhoto
   * @description Uploads a photo to Firebase Storage. Returns the download URL.
   * @external https://firebase.google.com/docs/storage/web/upload-files
   */
  const uploadPhoto = async (file: File): Promise<string> => {
    try {
      // Create a unique filename for every new photo upload
      const filename = uuidv4() + getFileExtension(file.name);
      const storageRef = ref(storage, `${PATH_USER_PHOTOS}/${filename}`);
      const result = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(result.ref);

      return url;
    } catch (error) {
      const message = `Error uploading ${file.name}, please try again.`;
      const msg = error instanceof Error ? error.message : message;
      Sentry.captureException(msg);
      throw new Error(msg);
    }
  };

  /**
   * @name onAuthStateChanged
   * @external https://firebase.google.com/docs/reference/js/v9/auth.md#onauthstatechanged
   * @description Wraps the onAuthStateChanged function from Firebase.
   */
  const onAuthStateChanged = (handler: NextOrObserver<User>): Unsubscribe => {
    return firebaseOnAuthStateChanged(auth, handler);
  };

  /**
   * @name onIdTokenChanged
   * @external https://firebase.google.com/docs/reference/js/v9/auth.md#onidtokenchanged
   * @description Adds an observer for changes to the signed-in user's
   * ID token, which includes sign-in, sign-out, and token refresh events.
   */
  const onIdTokenChanged = (handler: NextOrObserver<User>): Unsubscribe => {
    return firebaseOnIdTokenChanged(auth, handler);
  };

  /**
   * @name resetPassword
   * @description Sends the user a reset password email using the email argument
   * or if that is not passed, use the email they are authenticated with.
   */
  const resetPassword = async (email?: string) => {
    if (!email && user.visitor) {
      throw new Error('Email address is required.');
    }

    try {
      const emailToUse = email ?? getEmail();
      const actionCodeSettings = {
        handleCodeInApp: false,
        url: `${CLIENT_BASE_URL}/login`
      };

      /**
       * If they don't have an account created yet then we will attempt to one
       * for them here. This will only do that if they don't already have an
       * account and they already a user (meaning they filled out the survey).
       *
       * This is because some people are trying to use the email they signed up
       * with to reset their password even without their unique "code" that was
       * emailed to them. This would be the case for brand new users who aren't
       * published yet or have lost their "code".
       */
      const response = await createAccount({
        variables: {
          input: {
            email: emailToUse
          }
        }
      });
      const succeeded = response.data?.createAccount?.status === 'succeeded';
      if (!succeeded) {
        throw new Error(`User ${emailToUse} has no account!`);
      }

      await sendPasswordResetEmail(auth, emailToUse, actionCodeSettings);

      toast('Email sent! Please check your inbox.', {
        bodyClassName: 'bg-turmeric',
        hideProgressBar: true,
        position: 'bottom-right'
      });
    } catch (e) {
      Sentry.captureException(e);

      toast('Failed to send email', {
        bodyClassName: 'bg-turmeric',
        hideProgressBar: true,
        position: 'bottom-right'
      });
    }
  };

  /**
   * @name signIn
   * @description Wraps the signIn function from Firebase.
   */
  const signIn = async (email: string, password: string, path = '') => {
    await setPersistence(auth, browserLocalPersistence);

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      setUser(() => ({
        email,
        name: res.user?.displayName ?? '',
        visitor: false
      }));

      if (path) router.push(path);
    } catch (error) {
      /**
       * All the possible errors from sign in should result in Invalid Credentials.
       * @external https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
       */
      throw new Error('Invalid credentials');
    }
  };

  /**
   * @name firebaseSignOut
   * @description Signs out, redirects to home, and sets a default user.
   */
  const signOut = async () => {
    await firebaseSignOut(auth);

    router.push('/login');

    removeCookie(COOKIE_AUTH_REFRESH, COOKIE_OPTIONS);
    removeCookie(COOKIE_AUTH_TOKEN, COOKIE_OPTIONS);

    setUser(() => userDefault);
  };

  /**
   * @name signUp
   * @description Wraps the creation and sign in functionality from Firebase.
   */
  const signUp = async (email: string, password: string, path = '') => {
    await setPersistence(auth, browserLocalPersistence);

    // This signs up and signs in in one call.
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);

      setUser(() => ({
        email,
        name: res.user?.displayName ?? '',
        visitor: false
      }));

      if (path) router.push(path);
    } catch (error: unknown) {
      const errorMessageMap: { [key: string]: string } = {
        'auth/email-already-in-use': 'Account already exists',
        'auth/invalid-email': 'Invalid email address',
        'auth/operation-not-allowed': 'Not allowed',
        'auth/weak-password': 'The password is too weak'
      };

      const code = get(error, 'code');
      const message = errorMessageMap[code];
      if (message) throw new Error(message);

      throw error;
    }
  };

  /**
   * @name updateName
   * @description Updates the user's name in Firebase Auth.
   */
  const updateName = async (firstName: string, lastName: string) => {
    const { currentUser } = auth;

    if (currentUser) {
      const fullName = `${firstName} ${lastName}`.trim();

      try {
        await updateProfile(currentUser, {
          displayName: fullName
        });
      } catch (error) {
        /**
         * Just logging to Sentry since there is no action a customer can do if
         * this fails.
         */
        const message = `Error setting display name user ${currentUser.uid}`;
        Sentry.captureException(new Error(message));
      }
    }
  };

  return {
    getEmail,
    onAuthStateChanged,
    onIdTokenChanged,
    resetPassword,
    signIn,
    signOut,
    signUp,
    updateName,
    uploadPhoto
  };
};
