import * as React from 'react';
import classnames from 'classnames';

import { AppLink } from '@src/services/app/components/AppLink';
import { SpotColor } from '@src/types';
import { isExternalLink } from '@src/utils/link';

import styles from './styles.module.css';

export interface HaldiButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bordersX?: SpotColor;
  bordersY?: SpotColor;
  href?: string;
  thick?: boolean;
}

/**
 * @name HaldiButton
 * @description As we're extending the native element here, we are unable
 * to use a boolean attribute for those two props. Other than that, we still
 * have a very raw button to work with
 */
export const HaldiButton = (props: HaldiButtonProps) => {
  const {
    bordersX = 'turmeric',
    bordersY = 'pine',
    children,
    className,
    href,
    onClick,
    thick = false,
    type = 'button',
    ...rest
  } = props;

  // Styles
  const bordersLeftAndRight = styles[`border-x-${bordersX}`];
  const bordersTopAndBottom = styles[`border-y-${bordersY}`];

  // Setup
  const isExternal = isExternalLink(href);
  const theme = classnames(bordersLeftAndRight, bordersTopAndBottom);
  const cssComponent = classnames(styles.component, theme, className, {
    [styles.thick]: thick
  });

  /**
   * Anything starting with an HTTP is taking the user away from our
   * domain so we open that in a new tab
   */
  if (isExternal) {
    return (
      <a
        className={cssComponent}
        href={href}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={onClick as any}
        style={props.style}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  /**
   * Now we have our relative links (we have an href) which we're just
   * styling as a chunkier button
   */
  if (href) {
    return (
      <AppLink
        className={cssComponent}
        href={href}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={onClick as any}
        style={props.style}
      >
        {children}
      </AppLink>
    );
  }

  /**
   * And by default we're using a html button
   */
  return (
    // eslint-disable-next-line react/button-has-type
    <button {...rest} className={cssComponent} onClick={onClick} type={type}>
      {children}
    </button>
  );
};
