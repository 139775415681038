import * as React from 'react';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';

import { SITE_SURVEY_CTA, SITE_SURVEY_PATH } from '@src/config/constants';
import { useRoutes } from '@src/hooks/useRoutes';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavLogo } from '@src/services/navigation/components/NavLogo';
import { NavProfile } from '@src/services/navigation/components/NavProfile';

import styles from './navAcquisitionDesktop.module.css';

export interface NavAcquisitionDesktopProps {
  className?: string;
}

/**
 * @name NavAcquisitionDesktop
 * @description tbd...
 */
export const NavAcquisitionDesktop = (props: NavAcquisitionDesktopProps) => {
  const { className } = props;

  // Hooks
  const { isActive, isNavForest } = useRoutes();
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const background = isNavForest ? 'forest' : 'white';
  const isInfoPage = isActive.faq || isActive.privacy || isActive.terms;
  const isLogin = isActive.login || isActive['forgot-password'];

  // Styles
  const cssBackground = classnames({
    'bg-forest': isActive.homepage || isActive['refer-a-friend'],
    'bg-pine': isActive.brands,
    'bg-white': isActive['about-us'] || isInfoPage || isLogin
  });

  const cssComponent = classnames(cssBackground, styles.component, className);
  const cssLinkSurvey = classnames(styles.link, styles.survey);

  const active = styles.active;
  const cssAbout = classnames(styles.link, { [active]: isActive['about-us'] });
  const cssLogin = classnames(styles.link, { [active]: isActive.login });
  const cssRefer = classnames(styles.link, {
    [active]: isActive['refer-a-friend']
  });

  const renderLoginLink = () => (
    <AppLink className={cssLogin} href="/login">
      Login
    </AppLink>
  );

  const renderLogo = () => (
    <NavLogo background={background} className={styles.logo} />
  );

  const renderSurveyLink = () => (
    <AppLink className={cssLinkSurvey} href={SITE_SURVEY_PATH}>
      {SITE_SURVEY_CTA}
    </AppLink>
  );

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl flex items-center">
        {!isActive.homepage && renderLogo()}
        <span className="flex-grow" />
        <AppLink className={cssAbout} href="/about-us">
          Our story
        </AppLink>
        <AppLink className={cssRefer} href="/refer-a-friend">
          Free skincare
        </AppLink>
        {visitor && renderLoginLink()}
        {visitor && renderSurveyLink()}
        {!visitor && <NavProfile className="mx-2x" />}
      </div>
    </div>
  );
};
