import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { User } from 'firebase/auth';
import { useSetRecoilState } from 'recoil';

import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  COOKIE_OPTIONS
} from '@src/config/constants';
import { authAtom } from '@src/recoil/atoms/auth';
import { userAtom, userDefault } from '@src/recoil/atoms/user';
import { useFirebase } from './useFirebase';

/**
 * @name useFirebaseAuth
 * @description tbd...
 */
export function useFirebaseAuth(): void {
  // Hooks
  const setAuth = useSetRecoilState(authAtom);
  const setUser = useSetRecoilState(userAtom);
  const { onAuthStateChanged, onIdTokenChanged } = useFirebase();
  const [_cookies, setCookie, _removeCookie] = useCookies();

  // Store our cookie for use on future server side requests
  const setUserToken = async (user: User) => {
    const token = await user.getIdToken();
    const refresh = user.refreshToken;

    // Store our cookie for use on future server side requests
    if (refresh) setCookie(COOKIE_AUTH_REFRESH, refresh, COOKIE_OPTIONS);
    if (token) setCookie(COOKIE_AUTH_TOKEN, token, COOKIE_OPTIONS);
  };

  // Life Cycle
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (user) => {
      if (!user) return;

      await setUserToken(user);

      /**
       * We need to keep track of when we've actually done our first check for
       * if the user is logged in or not. This is used on protected routes to
       * make sure that the `visitor` attribute in the user reflects their auth
       * state.
       */
      setAuth({ checked: true });

      if (!user) {
        setUser(() => userDefault);
      } else {
        if (!user.email) {
          const message = `User ${user.uid} has no email address.`;
          Sentry.captureException(new Error(message));
        }

        setUser({
          email: user.email ?? undefined,
          name: user.displayName ?? '',
          visitor: false
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(async (user) => {
      if (!user) return;

      await setUserToken(user);
    });

    return () => unsubscribe();
  }, []);
}
