import { useRouter } from 'next/router';

type Routes =
  | 'about-us'
  | 'account'
  | 'bag'
  | 'brands'
  | 'faq'
  | 'forgot-password'
  | 'friends'
  | 'homepage'
  | 'login'
  | 'privacy'
  | 'profile'
  | 'refer-a-friend'
  | 'routine'
  | 'store'
  | 'summary'
  | 'surveys'
  | 'terms';

/**
 * @name useRoutes
 * @description This hook is used to...
 */
export const useRoutes = (): {
  isActive: {
    [key in Routes]: boolean;
  };
  isAcquisitionRoute: boolean;
  isBasicLayout: boolean;
  isNavForest: boolean;
} => {
  // Hooks
  const { pathname } = useRouter();

  /**
   * Useful for determining which route is active.
   */
  const isActive = {
    'about-us': pathname === '/about-us',
    account: pathname === '/profile/account',
    bag: pathname === '/store/bag',
    brands: pathname.startsWith('/brands'),
    faq: pathname === '/faq',
    'forgot-password': pathname === '/forgot-password',
    friends: pathname === '/profile/friends',
    homepage: pathname === '/',
    login: pathname === '/login',
    privacy: pathname === '/legal/privacy',
    profile: pathname === '/profile',
    'refer-a-friend': pathname === '/refer-a-friend',
    routine: pathname === '/profile/routine',
    store: pathname === '/store',
    summary: pathname === '/profile/summary',
    surveys: pathname.startsWith('/surveys'),
    terms: pathname === '/legal/terms'
  };

  const isLoginLogoutRoute = isActive.login || isActive['forgot-password'];
  const isGenericRoute = isActive.homepage || isActive['about-us'] || isActive['brands'] || isActive['refer-a-friend']; // prettier-ignore
  const isInfoRoute = isActive.privacy || isActive.terms || isActive.faq; // prettier-ignore
  const isAcquisitionRoute = isGenericRoute || isLoginLogoutRoute || isInfoRoute; // prettier-ignore

  const isBasicLayout = isActive.surveys;
  const isNavForest = isActive.homepage || isActive['refer-a-friend'];

  return {
    isAcquisitionRoute,
    isActive,
    isBasicLayout,
    isNavForest
  };
};
