import '@src/config/sentry';

import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import parser from 'ua-parser-js';
import Cookies from 'universal-cookie';

import { HEADER_USER_AGENT } from '@src/config/constants';
import { IS_BROWSER } from '@src/config/settings';
import { AppComponent } from '@src/services/app/components/AppComponent';

import '@src/styles/application.css';

export interface ApplicationProps extends AppProps {
  cookies: Record<string, any>;
  userAgent: parser.IResult;
}

/**
 * @name Application
 * @external https://nextjs.org/docs/advanced-features/custom-app
 * @description Next.js uses the App component to initialize pages.
 * You can override it and control the page initialization.
 */
export const Application = (props: ApplicationProps) => {
  const { cookies, Component, pageProps, userAgent } = props;

  // Setup
  const cookieData = new Cookies(cookies);
  const cookieProps = IS_BROWSER ? {} : { cookies: cookieData };

  return (
    <CookiesProvider {...cookieProps}>
      <AppComponent pageProps={pageProps} userAgent={userAgent}>
        <Component {...pageProps} />
      </AppComponent>
    </CookiesProvider>
  );
};

/**
 * Only uncomment this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * We connect to our GraphQL instance, potentially, on any page. This means
 * that any of our `useQuery` or `useMutation` actions will execute on the
 * server side, so we have to make our cookies available to the server
 */
(Application as any).getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const { req } = ctx;

  // Setup
  const cookies = IS_BROWSER ? document.cookie : (req as any)?.cookies ?? {};
  const headerUA = req?.headers[HEADER_USER_AGENT] ?? '';
  const userAgent = parser(headerUA);

  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, cookies, userAgent };
};

export default Application;
