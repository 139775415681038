import {
  SITE_DESCRIPTION,
  SITE_SLOGAN,
  SITE_TITLE
} from '@src/config/constants';
import { CLIENT_BASE_URL } from '@src/config/settings';

/**
 * @external https://ahrefs.com/blog/seo-meta-tags/
 * @description This is the base SEO structure
 */
const defaults = {
  author: `${SITE_TITLE}, Inc.`,
  description: SITE_DESCRIPTION,
  image: `${CLIENT_BASE_URL}/images/jpg/shareable-v2.jpg`,
  robots: 'index, follow',
  title: `${SITE_TITLE} - ${SITE_SLOGAN}`
};

export { defaults };
