import pino, { Logger, LoggerOptions } from 'pino';

import {
  COOKIE_AUTH_REFRESH,
  COOKIE_AUTH_TOKEN,
  HEADER_AUTH_KEY,
  HEADER_AUTH_REFRESH
} from '@src/config/constants';
import { IS_PRODUCTION } from '@src/config/settings';

const prettyConfig: LoggerOptions['prettyPrint'] = {
  colorize: true,
  ignore: 'hostname,pid',
  levelFirst: true,
  translateTime: 'SYS:standard'
};

/**
 * @name logger
 * @description A high-performance logger for NodeJS applications
 */
const logger: Logger = pino({
  name: 'haldi-client',
  prettyPrint: !IS_PRODUCTION ? prettyConfig : false,
  redact: [
    `req.cookies.${COOKIE_AUTH_REFRESH}`,
    `req.cookies.${COOKIE_AUTH_TOKEN}`,
    `req.headers.${HEADER_AUTH_KEY}`,
    `req.headers.${HEADER_AUTH_REFRESH}`
  ]
});

export { logger };
export type { Logger };
