import * as React from 'react';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';

import { COOKIE_OPTIONS, COOKIE_STORE_NP } from '@src/config/constants';
import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useRoutes } from '@src/hooks/useRoutes';

import styles from './styles.module.css';

export interface AppBannerProps {
  className?: string;
}

/**
 * @name AppBanner
 * @description A promotional banner that sits at the top of the application.
 * We can quickly update copy or the markup as needed and control tweak it
 * however we need to, as we need to.
 */
export const AppBanner = (props: AppBannerProps) => {
  const { className } = props;

  // Hooks
  const [cookies, setCookie] = useCookies([COOKIE_STORE_NP]);
  const { isActive } = useRoutes();
  const { data, loading } = useGetCustomerInfoQuery();

  // Setup
  const lifecycle = data?.customerInfo.lifecycle ?? [];
  const isClosed = cookies[COOKIE_STORE_NP] ? true : false;
  const isCustomer = lifecycle.includes('customer');

  // Styles
  const cssComponent = classnames(styles.component, styles.light, className);

  // Handlers
  const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCookie(COOKIE_STORE_NP, true, COOKIE_OPTIONS);
  };

  // 🔌 Short Circuit
  if (loading || isClosed || isCustomer || !isActive.store) return null;

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl relative font-beatrice-light">
        Welcome to the Haldi Fam 💛 Use <b>WELCOME10</b> for <b>10%</b> off your
        1st purchase &amp; free shipping!
      </div>

      <button className={styles.button} onClick={onClickClose} type="button">
        &times;
      </button>
    </div>
  );
};
