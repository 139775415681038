import * as React from 'react';
import { useCookies } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import parser from 'ua-parser-js';

import { COOKIE_AUTH_TOKEN } from '@src/config/constants';
import { useApollo } from '@src/hooks/useApollo';
import { useRoutes } from '@src/hooks/useRoutes';
import { userAgentAtom } from '@src/recoil/atoms/userAgent';
import { AppBanner } from '@src/services/app/components/AppBanner';
import { AppHead } from '@src/services/app/components/AppHead';
import { AppKustomerChat } from '@src/services/app/components/AppKustomerChat';
import { AppLifeCycle } from '@src/services/app/components/AppLifeCycle';
import { AppLoader } from '@src/services/app/components/AppLoader';
import { AppModal } from '@src/services/app/components/AppModal';
import { NavAcquisition } from '@src/services/navigation/components/NavAcquisition';
import { NavFooter } from '@src/services/navigation/components/NavFooter';
import { NavMobile } from '@src/services/navigation/components/NavMobile';
import { NavStore } from '@src/services/navigation/components/NavStore';

interface AppComponentProps {
  children: React.ReactNode;
  pageProps: any;
  userAgent: parser.IResult;
}

/**
 * @name AppComponent
 * @description This component is responsible for initializing apollo which
 * requires a valid / current token which we store in cookies. This has to be
 * a child of the `<CookiesProvider>` component.
 */
const AppComponent = (props: AppComponentProps) => {
  const { children, pageProps, userAgent } = props;

  // Hooks
  const { isAcquisitionRoute, isBasicLayout } = useRoutes();
  const [cookies] = useCookies([COOKIE_AUTH_TOKEN]);

  // Setup
  const Navigation = isAcquisitionRoute ? NavAcquisition : NavStore;
  const token = cookies[COOKIE_AUTH_TOKEN];

  // Hooks
  const apolloClient = useApollo(pageProps, token);

  // Handlers
  function initializeState({ set }: MutableSnapshot) {
    set(userAgentAtom, userAgent);
  }

  return (
    <ApolloProvider client={apolloClient}>
      <RecoilRoot initializeState={initializeState}>
        <ErrorBoundary>
          <AppHead />
          <AppLifeCycle />
          <AppLoader />
          {!isBasicLayout && <AppBanner />}
          {!isBasicLayout && <NavMobile />}
          {!isBasicLayout && <Navigation />}
          {children}
          {!isBasicLayout && <NavFooter />}
          <ToastContainer />
          <AppModal />
          <AppKustomerChat />
        </ErrorBoundary>
      </RecoilRoot>
    </ApolloProvider>
  );
};

export { AppComponent };
export type { AppComponentProps };
