import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { useGetCustomerInfoQuery } from '@src/generated/hooks';
import { useFirebase } from '@src/hooks/useFirebase';
import { useRoutes } from '@src/hooks/useRoutes';
import { userAtom } from '@src/recoil/atoms/user';
import { AppLink } from '@src/services/app/components/AppLink';

import styles from './styles.module.css';

export interface DropdownProfileProps {
  className?: string;
}

/**
 * @name DropdownProfile
 * @description The dropdown used in our "AppUserProfile" component
 */
export const DropdownProfile = (props: DropdownProfileProps) => {
  const { className } = props;

  // Hooks
  const { data } = useGetCustomerInfoQuery();
  const { isActive } = useRoutes();
  const { query } = useRouter();
  const { signOut } = useFirebase();
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const created = data?.customerInfo.created ?? false;
  const isLogin = isActive['login'] || isActive['forgot-password'];
  const isVisitor = !query.code && visitor;
  const linkMyAccount = visitor ? '/login' : '/profile/account';

  // Styles
  const active = styles.active;
  const tailwind = 'flex flex-col';
  const cssComponent = classnames(tailwind, styles.component, className);

  const cssAccount = classnames(styles.small, { [active]: isActive.account });
  const cssFriends = classnames({ [active]: isActive.friends });
  const cssLogin = classnames(styles.small, { [active]: isLogin });
  const cssRoutine = classnames({ [active]: isActive.routine });
  const cssStore = classnames({ [active]: isActive.store });
  const cssSummary = classnames(styles.small, { [active]: isActive.summary });

  // Handlers
  const onClickLogout = async () => {
    signOut();
  };

  // Markup
  const renderedMyAccount = (
    <AppLink className={cssAccount} href={linkMyAccount}>
      My account
    </AppLink>
  );

  const renderedLogin = (
    <AppLink className={cssLogin} href="/login">
      Log in
    </AppLink>
  );

  const renderedLogout = (
    <button className={styles.small} onClick={onClickLogout} type="button">
      Log out
    </button>
  );

  return (
    <div className={cssComponent}>
      <AppLink className={cssRoutine} href="/profile/routine">
        My routine
      </AppLink>
      <AppLink className={cssStore} href="/store">
        My store
      </AppLink>
      <AppLink className={cssFriends} href="/profile/friends">
        My friends
      </AppLink>
      <hr />
      <AppLink className={cssSummary} href="/profile/summary">
        My skin profile
      </AppLink>
      {created && renderedMyAccount}
      {!visitor && renderedLogout}
      {isVisitor && renderedLogin}
    </div>
  );
};
