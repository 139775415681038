import * as React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { SITE_SURVEY_PATH } from '@src/config/constants';
import { useRoutes } from '@src/hooks/useRoutes';
import { userAtom } from '@src/recoil/atoms/user';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { AppLink } from '@src/services/app/components/AppLink';
import { NavCart } from '@src/services/navigation/components/NavCart';
import { NavLogo } from '@src/services/navigation/components/NavLogo';
import { NavProfile } from '@src/services/navigation/components/NavProfile';
import { NavTracker } from '@src/services/navigation/components/NavTracker';

import styles from './navStoreDesktop.module.css';

export interface NavStoreDesktopProps {
  className?: string;
}

/**
 * @name NavStoreDesktop
 * @description Very simple navigation bar we want across the application
 */
export const NavStoreDesktop = (props: NavStoreDesktopProps) => {
  const { className } = props;

  // Hooks
  const navRef = React.useRef<HTMLElement>(null);
  const { isActive } = useRoutes();
  const { query } = useRouter();
  const { visitor } = useRecoilValue(userAtom);

  // Setup
  const { code } = query;
  const isLogin = isActive['forgot-password'] || isActive.login;
  const isGenericNav = isActive['about-us'] || isActive.homepage || isLogin || isActive['refer-a-friend']; // prettier-ignore
  const isVisitor = !code && visitor;

  // Styles
  const active = styles.active;
  const cssComponent = classnames(styles.component, className);
  const cssAbout = classnames(styles.link, { [active]: isActive['about-us'] });
  const cssLogin = classnames(styles.link, { [active]: isActive.login });
  const cssRefer = classnames(styles.link, { [active]: isActive['refer-a-friend'] }); // prettier-ignore
  const cssLogo = classnames('ml-2x p-0', styles.logo);
  const cssRoutine = classnames('ml-2x px-6x', styles.routine);

  // Markup
  const renderGeneralLinks = () => {
    if (!isGenericNav || isLogin) return null;

    return (
      <>
        <AppLink className={cssAbout} href="/about-us">
          Our Story
        </AppLink>
        <AppLink className={cssRefer} href="/refer-a-friend">
          Free Skincare
        </AppLink>
      </>
    );
  };

  const renderLogin = () => {
    if (!isVisitor || isLogin) return null;

    return (
      <AppLink className={cssLogin} href="/login">
        Login
      </AppLink>
    );
  };

  const renderRoutine = () => {
    if (!isVisitor || isLogin) return null;

    return (
      <HaldiButton
        className={cssRoutine}
        href={SITE_SURVEY_PATH}
        style={{ minWidth: 240 }}
      >
        Get your routine
      </HaldiButton>
    );
  };

  return (
    <nav className={cssComponent} ref={navRef}>
      <div className="ui-container-xl flex h-full items-center align-middle">
        <NavLogo className={cssLogo} />
        <div className="flex-1" />
        {renderGeneralLinks()}
        {renderLogin()}
        {!isVisitor && <NavTracker className="mx-2x" />}
        {!isVisitor && <NavProfile className="px-2x" />}
        {!isVisitor && <NavCart className="mr-2x" />}
        {renderRoutine()}
      </div>
    </nav>
  );
};
