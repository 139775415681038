import { GRAPHQL_URL } from '@src/config/settings';

/**
 * @name refreshAuthToken
 * @description If we have a refreshToken we try to use it to refresh the auth token
 */
export const refreshAuthToken = (refresh: string): Promise<any> => {
  const variables = { input: { refresh } };
  const query = `
    query getAuthentication($input: AuthenticationInput!) {
      authentication(input: $input) {
        expiresIn
        idToken
        projectId
        refreshToken
        tokenType
        userId
      }
    }
  `;

  const body = JSON.stringify({ query, variables });
  const json = fetch(GRAPHQL_URL, {
    body,
    cache: 'no-cache',
    credentials: 'omit',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }).then((response) => response.json());

  return json;
};

/**
 * A simple function to set cookies w/o access to our React Hooks which
 * we need for our Apollo middleware which requests a new "id_token" when it
 * has expired as it's "short lived"
 */
export const setCookie = (name: string, value: string): void => {
  const date = new Date();
  const oneHour = 1000 * 60 * 60;

  date.setTime(date.getTime() + oneHour);

  const expires = 'expires=' + date.toUTCString();

  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
};
