import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import {
  IS_PRODUCTION_ENV,
  NODE_ENV,
  VERCEL_ENV,
  VERCEL_SHA
} from './settings';

/**
 * Set tracesSampleRate to 1.0 to capture 100% of transactions for
 * performance monitoring. We recommend adjusting this value in production
 */
Sentry.init({
  attachStacktrace: true,
  dsn: 'https://9a8de028f2a7451b9cd978885401385c@o287843.ingest.sentry.io/5816465',
  enabled: IS_PRODUCTION_ENV,
  environment: VERCEL_ENV,
  ignoreErrors: [
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    'TypeError: cancelled',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',

    // https://stackoverflow.com/questions/67224859/typeerror-illegal-invocation
    'TypeError: Illegal invocation'
  ],
  integrations: [new Integrations.BrowserTracing()],
  release: VERCEL_SHA || NODE_ENV,
  tracesSampleRate: 0.1
});

// Sentry.captureException(new Error('Testing Sentry configuration'));
