import * as React from 'react';
import { useRouter } from 'next/router';

import { HaldiButton } from '@src/routes/common/components/HaldiButton';

export interface DropdownCartEmptyProps {
  className?: string;
}

/**
 * @name DropdownCartEmpty
 * @description When the users cart is empty we prompt them with a bit of
 * UI to encourage them to visit the store and get shopping
 */
export const DropdownCartEmpty = (props: DropdownCartEmptyProps) => {
  const { className } = props;

  // Hooks
  const { push } = useRouter();

  // Handlers
  const onClickStore = () => {
    push('/store');
  };

  return (
    <div className={className}>
      <div className="m-0">Your bag is empty</div>
      <HaldiButton
        bordersX="forest"
        bordersY="forest"
        className="font-beatrice-medium"
        onClick={onClickStore}
      >
        Shop now
      </HaldiButton>
    </div>
  );
};
