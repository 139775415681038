import * as React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';

import { DropdownCartButton } from '@src/components/DropdownCartButton';
import { DropdownCartEmpty } from '@src/components/DropdownCartEmpty';
import { DropdownCartItems } from '@src/components/DropdownCartItems';
import {
  GetCustomerCartQuery,
  GetCustomerCartQueryVariables as Variables
} from '@src/generated/hooks';
import * as getCustomerCart from '@src/graphql/getCustomerCart.graphql';
import { getProductTotals } from '@src/services/navigation/utils';

import styles from './styles.module.css';

export interface DropdownCartProps {
  className?: string;
}

/**
 * @name DropdownCart
 * @description Shows the users current cart count and reveals the truncated
 * list of their current cart on hover. This component handles grabbing the
 * current cart data and passes the necessary bits down to some stateless
 * UI components
 */
export const DropdownCart = (props: DropdownCartProps) => {
  const { className } = props;

  // Hooks
  const response = useQuery<GetCustomerCartQuery, Variables>(getCustomerCart);

  // Setup
  const { data } = response;
  const products = data?.customerCart.products ?? [];
  const quantity = products.reduce(getProductTotals, 0);

  // Styles
  const cssComponent = classnames(styles.component, className, {
    [styles.empty]: quantity === 0
  });

  // 🔌 Short Circuit
  if (!products || !products.length) {
    return <DropdownCartEmpty className={cssComponent} />;
  }

  return (
    <div {...props} className={cssComponent}>
      <div className="mb-2x text-center text-12 uppercase">Added to bag</div>
      <DropdownCartItems data={products} />
      <DropdownCartButton count={quantity} />
    </div>
  );
};
